import React, { useState, useEffect, useRef, useCallback, MouseEventHandler } from "react";
import "../ChatMessage/ChatView.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import { MessageBox, ReplyMessage } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';

import {
  subPath,
  ENC_DEC_KEY,
  decodeToken,
} from "../../../../Constants/Global";
import { Badge, Button, Grid, InputBase, Popover, TextField, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../Constants/hooks";
import CryptoJS from "crypto-js";
import {
  getChatView,
  getPinnedMessage,
  savePinnedMessage,
  deleteChatMessage,
  updateChatMessage,
  deletePinnedMessage,
  muteMessage,
  unMutechat,
} from "../../../../Redux/Chatview/action";
import ChatProfile from "./ChatProfile";
import ForwardMessageView from "./FarwardMessage/ForwardMessageView";
import ChatReply from "./ChatReply";
import ChatMenu from "./ChatMenu";
import ReplymsgTooltip from "./MessageboxTooltip/ReplymsgTooltip";
import PinnedMsgModel from "./MessageboxTooltip/PinnedMsgModel";
import PreviewAttachment from "./PreviewAttachment";
import moment from "moment";
import { sendMedia } from "../../../../Redux/circle/action";
import AudioPlayer from "react-h5-audio-player";
import H5AudioPlayer from "react-h5-audio-player";
import ThumbnailCarousel from "./ThumbnailCarousel";
import { getChatList, isMediaShow } from "../../../../Redux/chatList/action";
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import CloseIcon from '@mui/icons-material/Close';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
interface ChatMessage {
  senderName: string;
  recipientName?: string;
  message: any;
  status: string;
  action: string;
  senderId: string;
  recipientId: string;
  groupId: string;
  groupName: string;
  type: string;
  senderProfilePicture: string;
  replyTo: any;
  replySenderName: string;
  replySenderId: string;
  deviceToken: string;
  groupProfilePicture: string;
  department: string;
  designation: string;
  colorCode: any;
  chatId: string;
}
const STRINGS = {
  new: "new",
  text: "TEXT",
};
interface DeletePinnedMessage {
  pinnedMessageId: string;
}
interface ChatvieProps {
  chatDetails: any;
  userValue: any;
  chatID: any;
}
const userIdSend: string | null = decodeToken()?.id ?? null;
const ChatView: React.FC<ChatvieProps> = (chatDetails: any, chatID: any) => {
  const [chatHover, setChatHover] = useState<null | HTMLElement>(null);
  const [empty, setEmpty] = useState<null | boolean>(false);
  const [anchorElPin, setAnchorElPin] = React.useState<null | HTMLElement>(
    null
  );
  console.log(chatDetails, "chatDetailschatDetails");
  

  const chatPageNumber: any = useRef(1);
  const [forwardMsg, setForwardMsg] = useState({});
  //update seenAt
  const [abortController, setAbortController] = useState(new AbortController());
  const dispatch = useAppDispatch();
  const newAbortController = new AbortController();
  const chatMessage: any = useAppSelector(
    (state: any) => state?.chatGetMessage?.chatView
  );
  console.log(chatMessage?.totalCount, "chatMessage");
  
  console.log("...........>>>>>>>>>chatMessage", chatMessage);
  const pinnedMessage: any = useAppSelector(
    (state: any) => state?.chatGetMessage?.pinnedMessage
  );
  
  console.log(pinnedMessage, "pinnedMessage");
  const userData: any = useAppSelector(
    (state: any) => state.chatListData.userInfo
  );
  const [msgchat, setMsgChat] = useState({
    privateChatMessages: [],
    totalNumberOfPages: 0,
  });

  
  const userProfilePicture =
    ".." + subPath + "/assets/images/images/PROFILE_PHOTO.png";
  const pinnedMessageData = {
    userId: chatDetails?.chatDetails?.senderId,
    recipientId: chatDetails?.chatDetails?.recipientId,
    groupId: "",
  };
  const userDetails = {
    senderId: chatDetails?.chatDetails?.senderId,
    recipientId: chatDetails?.chatDetails?.recipientId,
    pageNo: chatPageNumber.current, // Add this property
    rowPerPage: 10, // Add this property
  };

  console.log(abortController); 

  useEffect(() => {
    setAbortController(newAbortController);

    // chatDetails.chatDetails
    //   ?  (getChatView(userDetails, newAbortController))
    //   : "";
    // chatDetails.chatDetails
    //   ? dispatch(getPinnedMessage(pinnedMessageData, newAbortController))
    //   : "";
    setEmpty(chatDetails?.chatDetails?.empty);
    initWebSocket();
    changeChatView();

    // Cleanup function for socket closure
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [dispatch]);
  //Delete Private Chat

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setChatHover(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setChatHover(null);
  };

  const open = Boolean(chatHover);

  //

  // const encrypt = (text: string) => {
  //   return CryptoJS.AES.encrypt(text, ENC_DEC_KEY).toString();
  // };
  function encrypt(value: string) {
    const key = CryptoJS.enc.Utf8.parse(ENC_DEC_KEY);
    const encryptedData = CryptoJS.AES.encrypt(value, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encryptedData.toString();
  }
  const fetchChatList = () => {
    const data = {
      userId: userData.id,
      searchValue: "",
      pageNo: 1,
      rowPerPage: 10,
    };
    const signal = new AbortController();
    dispatch(getChatList(data, signal.signal));
  };

  function decrypt(value: string | CryptoJS.lib.CipherParams): string {
    if (value && value !== "") {
      try {
        const key = CryptoJS.enc.Utf8.parse(ENC_DEC_KEY);

        // Decrypting the value
        const decrypted = CryptoJS.AES.decrypt(value, key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        });

        // Converting decrypted value to UTF-8 string
        const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);

        // Logging decrypted value for debugging

        // Return decrypted string
        return decryptedString;
      } catch (error) {
        console.error("Error during decryption:", error);
        return "";
      }
    }

    return "";
  }
  const [hoveredMessageId, setHoveredMessageId] = useState<null | number>(null);
  const [hoveredMessageReply, setHoveredMessageReply] = useState<null | number>(
    null
  );
  const pinnedChatMessage = useAppSelector(
    (state: any) => state?.chatGetMessage?.pinnedMessage
  );
  console.log(pinnedChatMessage, "pinnedChatMessage");

  for (let index = 0; index < pinnedChatMessage.length; index++) {
    const element = pinnedChatMessage[index];
    console.log("element", element?.message);
    
    if (element?.message?.id === pinnedChatMessage[index]?.message?.id)  {
      const pinnedMessagess = pinnedChatMessage[index]?.message?.id;

      console.log("pinnedMessagess", pinnedMessagess)
    }
    
  }

  const [opens, setOpen] = useState(false);
  const [isPinShow, setIsPinShow] = useState(
    pinnedChatMessage.length != 0 ? true : false
  );
  const [highlightedMessageId, setHighlightedMessageId] = useState(null);
  
  const handlePinShow = (message: any) => {
    
     const isAlreadyPinned = pinnedChatMessage.some(
    (pinnedMsg: any) => pinnedMsg.message.id === message.message.id
  );

  if (isAlreadyPinned) {
    console.log("Message is already pinned.");
    return;
  }
    const data = {
      userId: chatDetails?.chatDetails?.senderId,
      recipientId: chatDetails?.chatDetails?.recipientId,
      groupId: message.groupId,
      messageId: message.message.id,
      message: message,
    };
    dispatch(savePinnedMessage(data))
      .then(() => {
        handlePinnedMsg();
      })
      .catch((error) => {
        setIsPinShow(false);
        console.error("Failed to save pinned message:", error);
      });
  };
  const handlePinnedMsg = () => {
    dispatch(getPinnedMessage(pinnedMessageData, newAbortController));
    setIsPinShow(true);
    setAnchorEl(null);
    setHighlightedMessageId(null);

  };
  const handleClickPinned = (pinnedMsg: any) => {
    setHighlightedMessageId(pinnedMsg.message.id);
    setTimeout(() => {
      setHighlightedMessageId(null);
    }, 3000);
  };
  const unPinnedMessage = (id: any) => {
    const data: DeletePinnedMessage = {
      pinnedMessageId: id,
    };
    console.log(data, "datadata");
    
    dispatch(deletePinnedMessage(data))
      .then(() => {
        dispatch(getPinnedMessage(pinnedMessageData, newAbortController));
        console.log("unPinnedMessage", pinnedMessageData);
        
      })
      .catch((error) => {
        setIsPinShow(true);
        console.error("Failed to un pinned message:", error);
      });
  };




  // WebSocket
  const socketRef = useRef<WebSocket | null>(null);
  const [connectionState, setConnectionState] = useState<
    "CONNECTING" | "OPEN" | "CLOSED"
  >("CONNECTING");
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [messageInput, setMessageInput] = useState("");
  const [isProfileShow, setIsProfileShow] = useState(false);
  const [isForwardMessageShow, setIsForwardMessageShow] = useState(false);

  const initWebSocket = () => {
    const newSocket = new WebSocket(
      `wss://trove-dev-gateway.citpl.info/chat-module/ws/` +
        chatDetails?.chatDetails?.senderId +
        `/goldfish_arm64`
    );
    // const newSocket = new WebSocket(
    //   `wss://trovekafkachat.ckdigital.in/api/ws/66750bb7a852b74175fb4c80/goldfish_arm64`
    // );
    socketRef.current = newSocket;

    newSocket.onopen = () => {
      console.log("WebSocket connection established.");
      setConnectionState("OPEN");
    };

    newSocket.onclose = () => {
      console.log("WebSocket connection closed.");
      setConnectionState("CLOSED");
      // Reopen WebSocket after a delay (optional)
      // setTimeout(() => {
      initWebSocket();
      // }, 1000); // Try reconnecting after 5 seconds
    };

    newSocket.onmessage = (event) => {
      console.log(event?.data, "event");
      if (event?.data?.senderId?.length > 0) {
        const receivedMessage = JSON.parse(event.data);
        setMessages((prevMessages) => [...prevMessages, receivedMessage]);
      }
    };
  };
 
  const sendMessage = () => {
    if (
      socketRef.current &&
      connectionState === "OPEN" &&
      messageInput.trim() !== ""
    ) {
      const messageData: ChatMessage = {
        
        action: STRINGS.new,
        groupId: "",
        groupName: "",
        groupProfilePicture: "",
        message: JSON.stringify({
          id: generateUniqueId(),
          content: encrypt(messageInput),
          caption: "",
          type: STRINGS.text,
          sendAt: new Date(),
          updatedAt: new Date(),
          deliveredAt: "",
          seenAt: "",
        }),
        recipientId: chatDetails?.chatDetails?.recipientId,
        recipientName: chatDetails?.chatDetails?.recipientName,
        replySenderId: "",
        replySenderName: "",
        replyTo: "",
        senderId: chatDetails?.chatDetails?.senderId,
        senderName: chatDetails?.chatDetails?.senderName,
        chatId: chatDetails?.chatDetails?.chatId,
        senderProfilePicture: "",
        department: "",
        designation: "",
        colorCode: "",
        type: STRINGS.text,
        deviceToken: "",
        status: "sent",
      };
      console.log(messageData, "messageDatamessageData");

      socketRef.current.send(JSON.stringify(messageData));
      chatPageNumber.current = chatPageNumber.current ? 0 : (chatPageNumber.current -= 1);

      dispatch(
        getChatView(
          {
            senderId: chatDetails?.chatDetails?.senderId,
            recipientId: chatDetails?.chatDetails?.recipientId,
            pageNo: chatPageNumber.current,
            rowPerPage: 10,
          },
          newAbortController
        )
      );
      // setMsgChat((prevMsgChat: any) => ({
      //   ...prevMsgChat,
      //   privateChatMessages: messageData,
      // }));
      if (chatMessage?.privateChatMessages === "") {
        var ChatData: any = [...(msgchat?.privateChatMessages || [])];
      } else {
        var ChatData: any = [
          // ...(msgchat?.privateChatMessages || []),
          ...(chatMessage?.privateChatMessages || []),
        ];
      }
      ChatData.sort((a: any, b: any) => {
        const dateA = new Date(a.message.updatedAt).getTime();
        const dateB = new Date(b.message.updatedAt).getTime();
  
        return dateA - dateB;
      });
      const uniqueMessages = ChatData.filter(
        (message: any, index: any, self: any) =>
          index === self.findIndex((m: any) => m.id === message.id)
      );
      console.log("uniqueMessages", uniqueMessages);
      setMsgChat((prevMsgChat: any) => ({
        ...prevMsgChat,
        privateChatMessages: uniqueMessages,
        totalNumberOfPages: chatMessage?.totalNumberOfPages,
      }));
setTimeout(()=>{
  fetchChatList();
  handleFetchPrevChat(true);
},1000)
      clearInput();

    } else {
      initWebSocket();
      
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  const clearInput = () => {
    setMessageInput("");
  };

  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const changeChatView = () => {
    if (empty == true) {
      setMessages([]);
      clearInput();
    }
  };
  const handleProfileClick = () => {
    setIsProfileShow(!isProfileShow);
  };

  const handleForwardMessageShow = (message: any) => {
    setIsForwardMessageShow(true);
    setForwardMsg(message);
  };
  const handleCloseForwardMessage = () => {
    setIsForwardMessageShow(false);
  };
  // pin

  const handleClickPin = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElPin(event.currentTarget);
  };

  const handleClosePin = () => {
    setAnchorElPin(null);
  };

  const handleDeleteMessage = (message: any) => {
    console.log("hello");
    dispatch(deleteChatMessage(message))
      .then(() => {
        const newArray: any = msgchat.privateChatMessages.filter(
          (obj) => obj !== message
        );
        setMsgChat((prevMsgChat: any) => ({
          ...prevMsgChat,
          privateChatMessages: newArray,
        }));
        setTimeout(()=>{
          fetchChatList();
          handleFetchPrevChat(true);
        },1000)
      })
      .catch((error) => {
        console.error("Failed to delete Message:", error);
      });
  };
  const updateMessage = async (message: any) => {
    dispatch(updateChatMessage(message));
    const value = msgchat.privateChatMessages.map((obj: any) =>
      obj.message.id === message.message.id ? message : obj
    );
    setMsgChat((prevMsgChat: any) => ({
      ...prevMsgChat,
      privateChatMessages: value,
    }));
    fetchChatList();
    handleFetchPrevChat(true);
  };





  
  // edit

  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editedContent, setEditedContent] = useState("");
  // const inputRef = useRef(null);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Focus the input element when it is in the editing state
    if (divRef.current) {
      divRef.current.focus();
    }
  }, [editingMessageId]);

  const sendMessageEnter = (message: any) => {
    console.log(message, "chatDetails");
    const messageData: ChatMessage = {
      action: "edit",
      groupId: "",
      groupName: "",
      groupProfilePicture: "",
      chatId: chatDetails?.chatDetails?.chatId,

      recipientId: chatDetails?.chatDetails?.recipientId,
      recipientName: chatDetails?.chatDetails?.recipientName,
      replySenderId: "",
      replySenderName: "",
      replyTo: "",
      senderId: chatDetails?.chatDetails?.senderId,
      senderName: chatDetails?.chatDetails?.senderName,
      senderProfilePicture: "",
      department: chatDetails?.chatDetails?.recipientDepartment,
      designation: chatDetails?.chatDetails?.recipientDesignation,
      colorCode: "",
      type: "TEXT",
      deviceToken: "",
      status: "sent",
      message: {
        id: message.message.id,
        content: encrypt(editedContent),
        caption: "",
        type: "TEXT",
        sendAt: new Date(),
        updatedAt: new Date(),
        deliveredAt: "",
        seenAt: "",
      },
    };
    updateMessage(messageData);
  };

  const handleEditClick = (messageId: any, currentContent: any) => {
    setOpen(true);
    setEditingMessageId(messageId);
    setEditedContent(currentContent);
  };
  const handleEditClickReply = (messageId: any, currentContent: any) => {
    console.log(".....click", currentContent);

    setIsEditing(true);
    setEditingMessageId(messageId);
    setEditingMessageReplay(messageId);
    setEditedContentReplay(currentContent);
  };
  const handleContentChange = (event) => {
    setEditedContent(event.target.innerText);
  };
  useEffect(() => {
    if (divRef.current) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(divRef.current, divRef.current.childNodes.length);
      range.collapse(true);
      if (sel) {
        sel.removeAllRanges();
        sel.addRange(range);
      }
    }
  }, [editedContent]);

  const handleInput = () => {
    if (divRef.current) {
      setEditedContent(divRef.current.innerHTML);
    }
  };
  const handleSaveClick = (messageId: any) => {
    console.log(`Message ID: ${messageId}, New Content: ${editedContent}`);
    // Save the edited content here (e.g., update the message in your state or send it to a server)
    setEditingMessageId(null);
  };

  const handleKeyPress = (event: any, message: any) => {
    if (event.key === "Enter") {
      setOpen(true);
      event.preventDefault();
      handleSaveClick(editingMessageId);
      sendMessageEnter(message);
    }
  };
  const handleTick = (event: any, message: any) =>{
    setOpen(true);
    event.preventDefault();
        handleSaveClick(editingMessageId);
        sendMessageEnter(message);
  }
  const handleCloseEdit = () => {
    setOpen(false);
  };
  const handleCloseEditCheck =()=>{
    setEditingMessageId(null)
  }
  const handleClose = () =>{
    setEditingMessageId(null)
  }
  const closeButtonStyle = {
    fontSize: "24px",
    background: "none",
    border: "none",
    cursor: "pointer",
    color: "black",
    position: "relative",
    left: "",
    display: "flex", 
    justifyContent: "end"
    
  };

  const [isEditing, setIsEditing] = useState(false);
  const handleEditStart = () => {
    setIsEditing(true);
    // setEditedContent(decrypt(JSON.parse(message.message).content));
  };

  //reply
  const [isReplying, setIsReplying] = useState(false);
  const [replyingIndex, setReplyingIndex] = useState(null);
  const inputRefReply = useRef<HTMLInputElement>(null);
  const handelReplyShow =()=>{
    setIsReplying(!isReplying);
  }
  const handleReplyClick = (index: any) => {
    handelReplyShow();
    setReplyingIndex(index);
    const profilePicture = chatDetails.chatDetails.recipientProfilePicture
      ? chatDetails.chatDetails.recipientProfilePicture
      : `..${subPath}/assets/images/images/PROFILE_PHOTO.png`;

    const generateMessageData = () => {
      const messageData = {
        action: "new",
        groupId: "",
        groupName: "",
        groupProfilePicture: "",
        message: {
          id: generateUniqueId(),
          content: encrypt(editedContent),
          caption: "",
          type: "TEXT",
          sendAt: new Date(),
          updatedAt: new Date(),
          deliveredAt: "",
          seenAt: "",
        },
        recipientId: chatDetails?.chatDetails?.recipientId,
        recipientName: chatDetails?.chatDetails?.recipientName,
        replySenderId: "",
        replySenderName: "",
        replyTo: "",
        senderId: chatDetails?.chatDetails?.senderId,
        senderName: chatDetails?.chatDetails?.senderName,
        senderProfilePicture: profilePicture, // Update this field with the profile picture
        department: "",
        designation: "",
        colorCode: "",
        type: "TEXT",
        deviceToken: "",
        status: "sent",
      };
      useEffect(() => {
        // Focus the input element when it is in the editing state
        if (divRef.current) {
          divRef.current.focus();
        }
      }, [ReplyMessage]);

      const sendMessageEnter = () => {
        console.log(`Send Message: ${ReplyMessage}`);
        const messageData = {
          action: "new",
          groupId: "",
          groupName: "",
          groupProfilePicture: "",
          message: {
            id: generateUniqueId(),
            content: encrypt(editedContent),
            caption: "",
            type: "TEXT",
            sendAt: new Date(),
            updatedAt: new Date(),
            deliveredAt: "",
            seenAt: "",
          },
          recipientId: chatDetails?.chatDetails?.recipientId,
          recipientName: chatDetails?.chatDetails?.recipientName,
          replySenderId: "",
          replySenderName: "",
          replyTo: "",
          senderId: chatDetails?.chatDetails?.senderId,
          senderName: chatDetails?.chatDetails?.senderName,
          senderProfilePicture: "",
          department: "",
          designation: "",
          colorCode: "",
          type: "TEXT",
          deviceToken: "",
          status: "sent",
        };
        console.log(messageData);
        updateMessage(messageData);
      };

      // const handleEditClick = (messageId: any, currentContent: any) => {
      //   setEditingMessageId(messageId);
      //   setEditedContent(currentContent);
      // };

      // const handleContentChange = (event: any) => {
      //   setEditedContent(event.target.value);
      // };

      const handleSaveClick = (messageId: any) => {
        console.log(`Message ID: ${messageId}, New Content: ${editedContent}`);
        // Save the edited content here (e.g., update the message in your state or send it to a server)
        setEditingMessageId(null);
      };

      const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
          event.preventDefault();
          handleSaveClick(editingMessageId);
          sendMessageEnter();
        }
      };

      const [isEditing, setIsEditing] = useState(false);
      const handleEditStart = () => {
        setIsEditing(true);
        // setEditedContent(decrypt(JSON.parse(message.message).content));
      };

      console.log(messageData);
      updateMessage(messageData);
    };
    // Call the function to generate and handle the new message data
    generateMessageData();

    // Call the function to generate and handle the new message data
    generateMessageData();
  };

  useEffect(() => {
    // Focus the input element when it is in the editing state
    if (inputRefReply.current) {
      inputRefReply.current.focus();
    }
  }, [editingMessageId]);

  //attachments

  const sendMessageEnterReply = (message) => {
    console.log(`Send Message: ${editedContentReplay}`);
    console.log("message:::::::::::::::::", message);
    console.log(
      "chatDetails?.chatDetails?:::::::::::::::::",
      chatDetails?.chatDetails
    );
    const messageData: ChatMessage = {
      action: "edit",
      groupId: "",
      groupName: "",
      groupProfilePicture: "",
      message: {
        id: message.message.id,
        content: encrypt(editedContentReplay),
        caption: "",
        type: STRINGS.text,
        sendAt: new Date(),
        updatedAt: new Date(),
        deliveredAt: "",
        seenAt: "",
      },
      recipientId: message?.recipientId,
      recipientName: message?.recipientName,
      replySenderId: message?.senderId,
      replySenderName: message?.senderName,
      replyTo: {
        id: message.replyTo.id,
        type: "TEXT",
        content: encrypt(message?.message?.content),
        caption: "",
        size: 0,
        name: null,
        seenAt: null,
        sendAt: new Date(),
        updatedAt: new Date(),
        createdAt: new Date(),
      },
      senderId: message?.senderId,
      senderName: message?.senderName,
      senderProfilePicture: message?.replySenderProfilePicture,
      department: chatDetails?.chatDetails?.recipientDepartment,
      designation: chatDetails?.chatDetails?.recipientDesignation,
      colorCode: chatDetails?.userList?.userList?.colorCode,
      type: "TEXT",
      deviceToken: "",
      status: "sent",
      chatId: ""
    };
    console.log(messageData);
    updateMessageReply(messageData);
  };
  const updateMessageReply = async (message: any) => {
    dispatch(updateChatMessage(message));
    const value = msgchat.privateChatMessages.map((obj: any) =>
      obj.message.id ? message : obj
    );
    setMsgChat((prevMsgChat: any) => ({
      ...prevMsgChat,
      privateChatMessages: value,
    }));
    setTimeout(() => {
      fetchChatList();
      handleFetchPrevChat(true);
    });
  };
  const handleKeyPressReply = (event: any, message: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick(editingMessageReplay);
      sendMessageEnterReply(message);
    }
  };
  const handleContentChangeReply = (event: any) => {
    setEditedContentReplay(event.target.value);
  };
  const [imagePreview, setImagePreview] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [files, setFiles] = useState([]);
  const [editingMessageReplay, setEditingMessageReplay] = useState(null);
  const [editedContentReplay, setEditedContentReplay] = useState("");

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Triggering input file selection
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files: any = Array.from(event.target.files).map((file, index) => ({
        file,
        id: `file_${index}`,
        previewUrl: URL.createObjectURL(file),
      }));
      setFiles(files);

      setImagePreview(true);
      if (files.length > 0) {
        setSelectedImage(files[0]);
      }
    }
  };
  const handleThumbnailClick = (image: File) => {
    setSelectedImage(image);
  };

  const onImageSelected = () => {
    const formData = new FormData();

    formData.append("senderId", chatDetails?.chatDetails?.senderId || "");
    formData.append("senderName", chatDetails?.chatDetails?.senderName || "");
    formData.append("recipientId", chatDetails?.chatDetails?.recipientId || "");
    formData.append(
      "recipientName",
      chatDetails?.chatDetails?.recipientName || ""
    );
    formData.append("department", "");
    formData.append("designation", "");
    formData.append("groupId", "");
    formData.append("groupName", "");
    formData.append("groupProfilePicture", "");
    formData.append("senderProfilePicture", "");
    formData.append("colorCode", "");
    formData.append("deviceToken", "");
    formData.append("replyTo", "");

    files.forEach((fileObj: { file: File }, index) => {
      const file = fileObj.file;
      if (file.type === "image/png") {
        formData.append(`images[${index}].media`, file);

        formData.append(`images[${index}].id`, generateUniqueId());

        formData.append(`images[${index}].caption`, "");
      } else if (file.type === "application/pdf") {
        formData.append(`documents[${index}].media`, file);

        formData.append(`documents[${index}].id`, generateUniqueId());

        formData.append(`documents[${index}].caption`, "");
      } else if (file.type.startsWith("video/")) {
        formData.append(`videos[${index}].media`, file);

        formData.append(`videos[${index}].id`, generateUniqueId());

        formData.append(`videos[${index}].caption`, "");
      } else if (file.type.startsWith("audio/")) {
        formData.append(`audios[${index}].media`, file);

        formData.append(`audios[${index}].id`, generateUniqueId());

        formData.append(`audios[${index}].caption`, "");
      }
      // Add similar blocks for videos and audios if needed
    });

    dispatch(sendMedia(formData))
      .then((res) => {
        const value: any = msgchat.privateChatMessages;

        res.data.data.forEach((imageData: any) => {
          value.push(imageData);
        });

        setMsgChat((prevMsgChat) => ({
          ...prevMsgChat,
          privateChatMessages: value,
        }));
        setTimeout(() => {
          fetchChatList();
          handleFetchPrevChat(true);
        });
        setImagePreview(false);
      })
      .catch((error: any) => {
        console.error("Error:", error);
      });
  };

  const handleViewFile = (index: number) => {
    setCurrentIndex(index);
  };

  const handleCloseView = () => {
    setCurrentIndex(null);
  };

  const handlePrevious = () => {
    if (currentIndex !== null && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex !== null && currentIndex < files.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  useEffect(() => {
    handlePinnedMsg();
    // setMsgChat(() => ({
    //   privateChatMessages: chatMessage.privateChatMessages,
    //   totalNumberOfPages: chatMessage?.totalNumberOfPages,
    // }));
  }, [chatDetails]);
  //scroll

  // const handleFetchNextChat = () => {
  //   chatPageNumber.current += 1;
  //   dispatch(
  //     getChatView(
  //       {
  //         senderId: chatDetails?.chatDetails?.senderId,
  //         recipientId: chatDetails?.chatDetails?.recipientId,
  //         pageNo: chatPageNumber.current,
  //         rowPerPage: 10,
  //       },
  //       newAbortController
  //     )
  //   );
  // };
  const [loadingMore, setLoadingMore] = useState(false); 
  const handleFetchNextChat = useCallback(async () => {
    if (loadingMore) return; 
    setLoadingMore(true);
    try {
      await dispatch(
        getChatView(
          {
            senderId: chatDetails?.chatDetails?.senderId,
            recipientId: chatDetails?.chatDetails?.recipientId,
            pageNo:0,
            rowPerPage: 1000,
          },
          newAbortController
        )
      );
      setLoadingMore(false); 
    } catch (error) {
     
    }
  }, [dispatch, chatDetails, loadingMore]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [msgchat.privateChatMessages, messages]);
  
  const chatContainerRef = useBottomScrollListener(handleFetchNextChat);

  // const scrollToBottom = () => {
  //   if (chatContainerRef.current) {
  //     chatContainerRef.current.scrollTop =
  //       chatContainerRef.current.scrollHeight;
  //   }
  // };

  const scrollToTop = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = chatContainerRef.current;
        if (scrollTop === 0) {
          handleFetchNextChat();
        }
      }
    };

    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener('scroll', handleScroll);
    
      return () => {
        chatContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleFetchNextChat]);

  useEffect(() => {
    scrollToBottom();
  }, [msgchat.privateChatMessages, messages]);
  useEffect(() => {
    if (chatMessage?.privateChatMessages === "") {
      var ChatData: any = [...(msgchat?.privateChatMessages || [])];
    } else {
      var ChatData: any = [
        // ...(msgchat?.privateChatMessages || []),
        ...(chatMessage?.privateChatMessages || []),
      ];
    }
    ChatData.sort((a: any, b: any) => {
      const dateA = new Date(a.message.updatedAt).getTime();
      const dateB = new Date(b.message.updatedAt).getTime();

      return dateA - dateB;
    });
    const uniqueMessages = ChatData.filter(
      (message: any, index: any, self: any) =>
        index === self.findIndex((m: any) => m.id === message.id)
    );
    console.log("uniqueMessages", uniqueMessages);
    setMsgChat((prevMsgChat: any) => ({
      ...prevMsgChat,
      privateChatMessages: uniqueMessages,
      totalNumberOfPages: chatMessage?.totalNumberOfPages,
    }));
    // if (chatContainerRef.current) {
    //   chatContainerRef.current.scrollTop =
    //     chatContainerRef.current.scrollHeight;
    // }
  }, [chatMessage, messages]);

  useEffect(() => {
    let ChatData: any = chatMessage?.privateChatMessages?.length
      ? [...chatMessage.privateChatMessages]
      : [...(msgchat?.privateChatMessages || [])];

    ChatData.sort((a: any, b: any) => {
      const dateA = new Date(a.message.updatedAt).getTime();
      const dateB = new Date(b.message.updatedAt).getTime();
      return dateA - dateB;
    });

    const uniqueMessages = ChatData.filter(
      (message: any, index: any, self: any) =>
        index === self.findIndex((m: any) => m.id === message.id)
    );

    console.log("uniqueMessages", uniqueMessages);
    setMsgChat((prevMsgChat: any) => ({
      ...prevMsgChat,
      privateChatMessages: uniqueMessages,
      totalNumberOfPages: chatMessage?.totalNumberOfPages,
    }));

    // scrollToTop();
  }, [chatMessage, messages]);

  // const [page, setPage] = useState(1);
  // useEffect(() => {
  //   handleFetchNextChat();
  // }, [page]);
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (chatContainerRef.current) {
  //       const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;

  //       console.log(scrollTop, scrollHeight, clientHeight);

  //       // Trigger fetch previous chat if scrolled to the top
  //       if (scrollTop === 0 && chatPageNumber.current > 0) {
  //         console.log("scrollTop.........", scrollTop)
  //         console.log("chatPageNumber.current.........", chatPageNumber.current)

  //         handleFetchPrevChat(false);
  //       }
  //       console.log("chatPageNumber.current.........", chatPageNumber.current)
  //       console.log("msgchat?.totalNumberOfPages.........", msgchat?.totalNumberOfPages)
  //       // Trigger fetch next chat if scrolled to the bottom
  //       if (scrollTop + clientHeight >= scrollHeight - 1 ) {
  //           setPage(prevPage => prevPage + 1);
  //       }
  //     }
  //   };

  //   const container = chatContainerRef.current;
  //   if (container) {
  //     container.addEventListener("scroll", handleScroll);
  //   }

  //   return () => {
  //     if (container) {
  //       container.removeEventListener("scroll", handleScroll);
  //     }
  //   };
  // }, [msgchat?.totalNumberOfPages, chatPageNumber.current]);

  // useEffect(() => {
  //   scrollToBottom();
  // }, [msgchat.privateChatMessages, messages]);

  const handleFetchPrevChat = (updateCall: any) => {
    chatPageNumber.current = updateCall ? 0 : (chatPageNumber.current -= 1);
    dispatch(
      getChatView(
        {
          senderId: chatDetails?.chatDetails?.senderId,
          recipientId: chatDetails?.chatDetails?.recipientId,
          pageNo: chatPageNumber.current,
          rowPerPage: 10,
        },
        newAbortController
      )
    );
  };

  // pinned
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (pinnedMessage) {
      handleClickPinned(pinnedMessage); 
    }

  };

  const openModel = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  // const unPinnedMessage = (id: string) => {
  // Function to unpin a message, implement as per your logic
  // };
  // console.log(pinnedChatMessage, "privateChatMessages");
  // console.log("chat printmessage. ===>.   ", msgchat.privateChatMessages);
  const sendButtonStyle = {
    // Define your send button styles here
  };
  const handleSendClick = () => {
    // Add your send button functionality here
    console.log("Send button clicked!");
    // For example, you could send the selectedImage to a server or perform some action
  };
  const isMediaView = useAppSelector(
    (state: any) => state?.chatListData?.isMediaView
  );
  const fileType = useAppSelector(
    (state: any) => state?.chatListData?.fileType
  );
  
  const handleToggleMediaView = (imageUrl, fileType) => {

    setSelectedImage(imageUrl);
    dispatch(
      isMediaShow({
        isMediaView: !isMediaView,
        imageURL: imageUrl,
        fileType: fileType,
      })
    );
  };
  const [audioview, setAudioview] = useState(false);
  const [audioOpen, setAudioOpen] = useState(false);

  const handleAudioview = (id) => {
    setAudioview(id);
    setAudioOpen(!audioOpen);
  };

  console.log(chatDetails, "chatDetails");
  

  // audio
  const CustomForwardButton = () => {
    return (
      <img
        src={`..${subPath}/assets/images/images/Refresh_Right.png`}
        alt="Refresh_Right"
        style={{
          border: "0px solid ",
          width: "40px",
          height: "40px",
          backgroundColor: "transparent",
        }}
      />
    );
  };
  const CustomRewindButton = () => {
    return (
      <img
        src={`..${subPath}/assets/images/images/Refresh_left.png`}
        alt="Refresh_Right"
        style={{
          border: "0px solid ",
          width: "40px",
          height: "40px",
          backgroundColor: "transparent",
        }}
      />
    );
  };
  const CustomPlayButton = () => {
    return (
      <img
        src={`..${subPath}/assets/images/images/play_icon1.png`}
        alt="Refresh_Right"
        style={{
          border: "0px solid ",
          width: "30px",
          height: "30px",
          backgroundColor: "transparent",
        }}
      />
    );
  };
  const CustomPauseButton = () => {
    return (
      <img
        src={`..${subPath}/assets/images/images/pause_icon.png`}
        alt="Refresh_Right"
        style={{
          border: "0px solid ",
          width: "20px",
          height: "20px",
          backgroundColor: "transparent",
        }}
      />
    );
  };
  // const ChatInput = () => {
    const [message, setMessage] = useState<string>('');
    const [emoji, setEmoji] = useState<null | HTMLElement>(null);
  
    const handleEmojiClick = (emojiData: EmojiClickData) => {
      setMessageInput(prevMessage => prevMessage + emojiData.emoji);
    };
  
    const handleOpenEmojiPicker = (event: React.MouseEvent<HTMLElement>) => {
     setEmoji(event.currentTarget);
    };
  
    const handleCloseEmojiPicker = () => {
     setEmoji(null);
    };
  

    const openemoji = Boolean(emoji);
    const emojid = openemoji ? 'emoji-popover' : undefined;
  
//
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const playerRef = useRef<H5AudioPlayer>(null);
  const handleListen = (e) => {
    setCurrentTime(e.target.currentTime);
    setDuration(e.target.duration);
  };

  const handleSeek = (time: any) => {
    if (playerRef.current && 'currentTime' in playerRef.current) {
      playerRef.current.currentTime = time;
    }
  };
  

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  //  mute message

  const handleMute = async () => {
    const muteData = {
      userId: chatDetails?.chatDetails?.senderId,
      recipientId: chatDetails?.chatDetails?.recipientId, 
      groupId: "", 
    };

    dispatch(muteMessage(muteData))

   
  };
  const handleUnMute = async () => {
    const unmuteData = {
      userId: chatDetails?.chatDetails?.senderId,
      recipientId: chatDetails?.chatDetails?.recipientId, 
      groupId: "", 
    };
    dispatch(unMutechat(unmuteData))
  }

  const [showMentionList, setShowMentionList] = useState(false);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [cursorPosition, setCursorPosition] = useState<any>(null);
  const chatListData = useAppSelector(
    (state: any) => state?.chatListData?.chatList
  );
  const arr:any = [];
  for(let i=0;i<chatListData?.chatMembersList.length;i++){
      arr.push(chatListData?.chatMembersList[i].userName)
  }
  const handleChangeInput = (e:any) =>{
    const inputValue = e.target.value;
      setMessageInput(inputValue);
  
      // Find the last occurrence of '@'
      const atSymbolIndex = inputValue.lastIndexOf("@");
  
      // If '@' is typed, show the mention list
      if (atSymbolIndex !== -1 && inputValue[atSymbolIndex + 1] !== " ") {
        const query = inputValue.slice(atSymbolIndex + 1).toLowerCase();
        const filtered = arr.filter((member) =>
          member?.toLowerCase()?.includes(query)
        );
        setFilteredMembers(filtered);
        setShowMentionList(true);
        setCursorPosition(atSymbolIndex + 1);
      } else {
        setShowMentionList(false);
      }
  }
  const handleMentionClick = (member) => {
    // Insert the selected member into the input
    const beforeAt = messageInput.slice(0, cursorPosition - 1);
    const afterAt = messageInput.slice(cursorPosition + member.length);
    const newMessageInput = `${beforeAt}@${member} ${afterAt}`;
  
    setMessageInput(newMessageInput);
    setShowMentionList(false);
  };
  

  return (
    <Grid item>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          // position: "fixed",
        }}
      >
        {imagePreview ? (
          <>
            <div>
              <Grid
                item
                onClick={() => setImagePreview(false)}
                sx={closeButtonStyle}
                mr={4}
              >
                <img
                  src={`..${subPath}/assets/images/images/Close_Icon.png`}
                  alt="close"
                  style={{ width: "17px", height: "17px" }}
                />
              </Grid>
            </div>
            {selectedImage && (
              <PreviewAttachment
                image={selectedImage}
                onImageSelected={onImageSelected}
              />
            )}
            <ThumbnailCarousel
              images={files}
              onThumbnailClick={handleThumbnailClick}
            />
            <Grid item mr={5} sx={{ display: "flex", justifyContent: "end" }}>
              <Button
                variant="outlined"
                onClick={onImageSelected}
                style={sendButtonStyle}
                sx={{
                  background:
                    "linear-gradient(90.31deg, #4CCE1F -65.38%, #119BFF 100%)",
                  borderRadius: "8px",
                  color: "#FFFFFF",
                  textTransform: "none",
                  fontWeight: "600",
                  fontSize: "14px",
                  lineHeight: "18px",
                  border: "1px solid #4CCE1F",
                }}
              >
                Send
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              sx={{
                height: "60px",
                position: "sticky",
                top: " 60px",
                zIndex: 1,
              }}
            >
              <AppBar
                position="static"
                sx={{
                  backgroundColor: "#ffff",
                  boxShadow: "none",
                  color: "#000000",
                  borderBottom: "1px solid #E5E5E5",
                }}
              >
                <Toolbar className="toolbar_chat">
                  <Grid container>
                    <Grid
                      item
                      xs={1.5}
                      md={0.8}
                      lg={0.6}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <IconButton
                        onClick={handleProfileClick}
                        className="profile_icon"
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                      >
                        {chatDetails?.chatDetails?.online === true ? (
                          <Badge
                            className="chat_badge"
                            badgeContent={
                              <Grid
                                item
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={`..${subPath}/assets/images/images/online_img.png`}
                                  alt="online"
                                  style={{ width: "10px", height: "10px" }}
                                />
                              </Grid>
                            }
                            color="primary"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                          >
                            <Avatar
                              alt="Profile"
                              sx={{
                                borderRadius: "25%",
                                width: "32px",
                                height: "32px",
                                // backgroundColor:chatDetails.userList.userList.colorCode || "#000000",
                              }}
                              src={
                                chatDetails.chatDetails.recipientProfilePicture
                                  ? chatDetails.chatDetails
                                      .recipientProfilePicture
                                  : chatDetails.chatDetails.recipientName
                                      .charAt(0)
                                      .toUpperCase()
                              }
                            >
                              {chatDetails.chatDetails.recipientName

                                .charAt(0)
                                .toUpperCase()}
                            </Avatar>
                          </Badge>
                        ) : (
                          <Avatar
                            alt="Profile"
                            sx={{
                              borderRadius: "25%",
                              width: "32px",
                              height: "32px",
                              // backgroundColor:chatDetails.userList.userList.colorCode || "#000000",
                            }}
                            src={
                              chatDetails.chatDetails.recipientProfilePicture
                                ? chatDetails.chatDetails
                                    .recipientProfilePicture
                                : chatDetails.chatDetails.recipientName

                                    .charAt(0)

                                    .toUpperCase()
                            }
                          >
                            {chatDetails.chatDetails.recipientName

                              .charAt(0)

                              .toUpperCase()}
                          </Avatar>
                        )}
                      </IconButton>
                    </Grid>
                    <Grid
                      item
                      xs={9.3}
                      md={10.6}
                      lg={10.9}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          flexGrow: 1,
                          fontSize: "16px",
                          color: "#000000",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {chatDetails.chatDetails.recipientName
                          .charAt(0)
                          .toUpperCase() +
                          chatDetails.chatDetails.recipientName.slice(1)}
                        <Typography
                          variant="subtitle1"
                          component="div"
                          sx={{
                            fontSize: "12px",
                            color: "#00000099",
                            fontWeight: "400",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {chatDetails.chatDetails.recipientDesignation}/{" "}
                          {chatDetails.chatDetails.recipientDepartment}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={0.5}
                      md={0.5}
                      lg={0.5}
                      sx={{
                        display: "flex",

                        alignItems: "center",

                        justifyContent: "start",
                      }}
                    >
                      <ChatMenu
                    chatData={chatDetails}
                        chatDetails={chatDetails}
                        recipientId={chatDetails.chatDetails.recipientId}
                        chatId={chatDetails?.chatDetails?.chatId}
                        senderId={chatDetails.chatDetails.senderId}
                        handleMute={handleMute}
                        handleUnMute={handleUnMute}
                      />
                    </Grid>
                  </Grid>
                </Toolbar>
              </AppBar>
            </Grid>
            {isPinShow && pinnedChatMessage?.length > 0 && (
  <>
    <Grid
      container
      sx={{
        position: "sticky",
        width: "100%",
        top: "125px",
        height: "40px",
        backgroundColor: "#FFFFFF",
        padding: "4px, 8px, 4px, 8px",
        borderRadius: "0px, 0px, 1px, 0px",
        borderBottom: "1px solid #E5E5E5",
        boxShadow: "0px 0px 2px 0px #0000001F",
        zIndex: 1,
      }}
    >
      <Grid
        item
        sm={0.6}
        sx={{
          display: "flex",
          paddingLeft: "8px",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <img
          src={`..${subPath}/assets/images/images/Pin_img_chat.png`}
          alt="pin chat"
          width="17"
          height="17"
        />
      </Grid>
      <Grid
        item
        sm={11.4}
        lg={11}
        onClick={handleClick}
        aria-describedby={id}
        sx={{
          display: "flex",
          alignItems: "center",
          fontSize: "12px",
          fontFamily: "Montserrat",
          color: "#000000",
          fontWeight: "400",
          cursor: "pointer",
        }}
      >
        {/* {pinnedChatMessage.map((pinnedMsg: any, index: number) => (
          <span 
            key={index} 
            onClick={() => handleClickPinned(pinnedMsg)} 
            style={{ marginRight: "5px" }}
          >
            <Grid item>
            {decrypt(pinnedMsg?.message?.content)?.substring(0, 10)},
            </Grid>
          </span>
        ))} */}
         {pinnedChatMessage.map((pinnedMsg, index) => {
        const messageContent = decrypt(pinnedMsg?.message?.content);
        const preview = messageContent?.substring(0, 10);

        return (
          <Tooltip key={index} title={messageContent} arrow>
            <span 
              onClick={() => handleClickPinned(pinnedMsg)} 
              style={{ marginRight: "5px", cursor: "pointer" }}
            >
              <Grid item>{preview},</Grid>
            </span>
          </Tooltip>
        );
      })}
        ...
      </Grid>
    </Grid>
    <Grid item>
    {pinnedChatMessage.map((pinnedMsg, index) => (
  <PinnedMsgModel
    key={index} 
    unPinnedMessage={() => unPinnedMessage(highlightedMessageId)}
    handleClickPinned={() => handleClickPinned(pinnedMsg)}
    id={pinnedMsg.id}
    open={openModel}
    anchorEl={anchorEl}
  />
))}
    </Grid>
  </>
)}

            <Box
              sx={{
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                padding: 0,
              }}
            >
              <Grid container>
                <Grid
                  component="div"
                  item
                  xs={8.5}
                  lg={8.5}
                  sx={{
                    gap: "10px",
                    height: "70vh",
                    overflow: "auto",
                    paddingTop: "25px",

                    paddingBottom: isPinShow ? "45px" : "13px",
                  }}
                  ref={chatContainerRef as React.RefObject<HTMLDivElement>}
                >
                  <Grid container >
                    {msgchat?.privateChatMessages?.map(
                      (message: any, index: number) => {
                        function vedio(arg0: React.JSX.Element) {
                          throw new Error("Function not implemented.");
                        }
                        const play: MouseEventHandler<HTMLButtonElement> = (event) => {
                          throw new Error("Function not implemented.");
                        }
                        return (
                          <>
                            {message?.replyTo !== null ? (
                              <Box
                                className={
                                  isProfileShow || isForwardMessageShow === true
                                    ? "Profile_show_cht"
                                    : "Profile_hide_cht"
                                }
                                sx={{
                                  padding: 0,
                                  margin: "auto",
                                  marginRight: "0px",
                                  paddingTop: "10px",
                                }}
                              >
                                <Grid
                                  item
                                  key={index}
                                  className={
                                    isProfileShow ||
                                    isForwardMessageShow === true
                                      ? "Profile_show_cht"
                                      : "Profile_hide_cht"
                                  }
                                  sx={{
                                    padding: 0,
                                    marginRight: "0px",
                                    display: "flex",
                                  }}
                                >
                                  <Avatar
                                    alt="Profile"
                                    sx={{
                                      borderRadius: "25%",
                                      width: "35px",
                                      height: "35px",
                                    }}
                                    src={message.senderProfilePicture}
                                  />
                                  <Box
                                    onMouseEnter={() =>
                                      setHoveredMessageReply(index)
                                    }
                                    onMouseLeave={() =>
                                      setHoveredMessageReply(null)
                                    }
                                    sx={{
                                      position: "relative",
                                      display: "flex",
                                      alignItems: "center",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    <Typography
                                      onMouseEnter={() =>
                                        setHoveredMessageReply(index)
                                      }
                                      onMouseLeave={() =>
                                        setHoveredMessageReply(null)
                                      }
                                      aria-owns={
                                        hoveredMessageId === index
                                          ? "mouse-over-popover"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      className="hide_Time"
                                    >
                                      <MessageBox
                                        position={"left"}
                                        type={"text"}
                                        text={
                                          editingMessageId ===
                                          index.toString() ? (
                                            <>
                                              {" "}
                                              <input
                                                ref={inputRefReply}
                                                style={{
                                                  cursor: "pointer",

                                                  border: "none",

                                                  width: "100%",

                                                  outline: "none",
                                                }}
                                                type="text"
                                                value={editedContentReplay}
                                                onKeyPress={(event) =>
                                                  handleKeyPressReply(
                                                    event,
                                                    message
                                                  )
                                                }
                                                onChange={
                                                  handleContentChangeReply
                                                }
                                              />
                                            </>
                                          ) : (
                                            <>
                                              {decrypt(
                                                message?.message?.content
                                              )}
                                              {message?.type === "IMAGE" && (
                                                <img
                                                  src={
                                                    message?.message?.content
                                                  }
                                                  alt="attachment"
                                                  className="message-image"
                                                  style={{
                                                    width: "100px",
                                                    height: "80px",
                                                    borderRadius: "6px",
                                                  }}
                                                  //  onClick={() => setImagePreview(message?.message?.content)}

                                                  onClick={() =>
                                                    handleToggleMediaView(
                                                      message?.message?.content,
                                                      message?.type
                                                    )
                                                  }
                                                />
                                              )}
                                              {message?.type == "DOCUMENT" && (
                                                <div
                                                  onClick={() =>
                                                    handleToggleMediaView(
                                                      message?.message?.content,
                                                      message?.type
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={`..${subPath}/assets/images/images/File_icon.png`}
                                                    alt="file_icon"
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                    }}
                                                  />

                                                  {message?.message?.name}
                                                </div>
                                              )}
                                              {message?.type === "AUDIO" && (
                                                <div
                                                  onClick={() =>
                                                    handleToggleMediaView(
                                                      message?.message?.content,
                                                      message?.type
                                                    )
                                                  }
                                                >
                                                  <div className="audio">
                                                    <audio controls>
                                                      <source
                                                        src={
                                                          message?.message
                                                            ?.content
                                                        }
                                                        type="audio/mpeg"
                                                      />
                                                      Your browser does not
                                                      support the audio element.
                                                    </audio>

                                                    {message?.message?.name}
                                                  </div>
                                                </div>
                                              )}
                                              {message?.type === "VIDEO" && (
                                                <div
                                                  onClick={() =>
                                                    handleToggleMediaView(
                                                      message?.message?.content,
                                                      message?.type
                                                    )
                                                  }
                                                >
                                                  <div className="video">
                                                    <div
                                                      className="video_preview"
                                                      style={{
                                                        backgroundImage:
                                                          "url(https://raw.githubusercontent.com/Ahmetaksungur/twitter-video-player-clone/master/92456705_506989316849451_7379405183454806542_n.jpg)",
                                                      }}
                                                    >
                                                      <button
                                                        className="videostart_btn"
                                                        onClick={play}
                                                      >
                                                        <img
                                                          src={`..${subPath}/assets/images/images/play_icon.png`}
                                                          alt="play"
                                                          style={{
                                                            width: "50px",
                                                            height: "50px",
                                                          }}
                                                        />
                                                      </button>
                                                    </div>
                                                  </div>
                                                  {message?.message?.name}
                                                </div>
                                              )}
                                            </>
                                          )
                                        }
                                        title={
                                          <Grid
                                            container
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={
                                                message?.forwardMessage === true
                                                  ? 8
                                                  : 4
                                              }
                                              md={
                                                message?.forwardMessage === true
                                                  ? 4
                                                  : 8
                                              }
                                              lg={
                                                message?.forwardMessage === true
                                                  ? 5
                                                  : 8
                                              }
                                              className="sender_lable"
                                            >
                                              {message.senderName
                                                .charAt(0)
                                                .toUpperCase() +
                                                message.senderName.slice(1)}
                                            </Grid>
                                            <Grid
                                              item
                                              xs={
                                                message?.forwardMessage === true
                                                  ? 4
                                                  : 8
                                              }
                                              md={
                                                message?.forwardMessage === true
                                                  ? 8
                                                  : 4
                                              }
                                              lg={
                                                message?.forwardMessage === true
                                                  ? 7
                                                  : 4
                                              }
                                              sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                              }}
                                            >
                                              {message?.forwardMessage ===
                                              true ? (
                                                <Grid
                                                  item
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Grid item mr={1.5}>
                                                    <img
                                                      src={`..${subPath}/assets/images/images/Group_forward.png`}
                                                      style={{
                                                        width: "14px",
                                                        height: "10px",
                                                      }}
                                                    />
                                                  </Grid>
                                                  <Grid item mr={1.5}>
                                                    <Typography
                                                      sx={{
                                                        fontFamily:
                                                          "Montserrat",
                                                        fontSize: "11px",
                                                        fontWeight: "700",
                                                        color: "#848484",
                                                      }}
                                                    >
                                                      Forwarded
                                                    </Typography>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    mr={1.5}
                                                    sx={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <img
                                                      src={`..${subPath}/assets/images/images/Ellipse_Round.png`}
                                                      style={{
                                                        width: "6px",
                                                        height: "6px",
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              ) : (
                                                ""
                                              )}
                                              <Grid
                                                item
                                                sx={{
                                                  display: "flex",
                                                  color: "#414B54",
                                                  fontSize: "12px",
                                                  fontWeight: "400",
                                                  fontFamily: "Montserrat",
                                                }}
                                              >
                                                {moment(
                                                  message.message.sendAt
                                                ).format("hh:mm A")}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        }
                                        date={
                                          message?.messag &&
                                          new Date(
                                            JSON.parse(message?.message)?.sendAt
                                          )
                                        }
                                        id={index.toString()}
                                        className="chatbox_pad_reply"
                                        focus={true}
                                        titleColor={"#000000"}
                                        replyButton={false}
                                        removeButton={false}
                                        forwarded={false}
                                        status={"sent"}
                                        notch={true}
                                        retracted={false}
                                        reply={{
                                          title:
                                            message?.senderName
                                              ?.charAt(0)
                                              .toUpperCase() +
                                            message?.senderName?.slice(1),

                                          titleColor: "#000000",
                                          message: decrypt(
                                            message?.replyTo?.content
                                          ),
                                          photoURL: `..${subPath}/assets/images/images/user_profile_img.png`,
                                        }}
                                      />
                                    </Typography>
                                    {hoveredMessageReply === index && (
                                      <ReplymsgTooltip
                                      recep={message?.senderId}
                                        cahtRecep={
                                          chatDetails?.chatDetails?.senderId
                                        }
                                        forwardMessage={() =>
                                          handleForwardMessageShow(message)
                                        }
                                        DeleteMessage={() =>
                                          handleDeleteMessage(message)
                                        }
                                        PinClick={() => handlePinShow(message)}
                                        ReplyMessage={() =>
                                          handleReplyClick(index)
                                        }
                                        MouseEnter={() =>
                                          setHoveredMessageReply(index)
                                        }
                                        EditClick={() =>
                                          handleEditClickReply(
                                            index.toString(),

                                            decrypt(message?.message?.content)
                                          )
                                        }
                                      />
                                    )}
                                  </Box>
                                </Grid>
                              </Box>
                            ) : (
<>
                              {chatMessage?.totalCount > 0?( 
                                <Box
                                className={
                                  isProfileShow || isForwardMessageShow === true
                                    ? "Profile_show_cht"
                                    : "Profile_hide_cht"
                                }
                                sx={{
                                  padding: 0,
                                  margin: "auto",
                                  marginRight: "0px",
                                  paddingTop: "10px",
                                }}
                              >
                                <Grid
                                  item
                                  key={index}
                                  className={
                                    isProfileShow ||
                                    isForwardMessageShow === true
                                      ? "Profile_show_cht"
                                      : "Profile_hide_cht"
                                  }
                                  sx={{
                                    padding: 0,
                                    marginRight: "0px",
                                    display: "flex",
                                  }}
                                >
                                  <Avatar
                                    alt="Profile"
                                    sx={{
                                      borderRadius: "25%",
                                      width: "35px",
                                      height: "35px",
                                    }}
                                    src={message.senderProfilePicture}
                                  />
                                  <Box
                                    onMouseEnter={() =>
                                      setHoveredMessageReply(index)
                                    }
                                    onMouseLeave={() =>
                                      setHoveredMessageReply(null)
                                    }
                                    sx={{
                                      position: "relative",
                                      display: "flex",
                                      alignItems: "center",
                                      paddingLeft: "10px",
                                    }}
                                  >
                                    <Typography
                                      onMouseEnter={() =>
                                        setHoveredMessageReply(index)
                                      }
                                      onMouseLeave={() =>
                                        setHoveredMessageReply(null)
                                      }
                                      aria-owns={
                                        hoveredMessageReply === index
                                          ? "mouse-over-popover"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      className="hide_Time"
                                    >
                                      
                                      <MessageBox
                                        id={index.toString()}
                                        className={`chatbox_pad ${highlightedMessageId === message?.message?.id ? "ChatPin_highlight forwardMessage_pad" : ""} ${message?.forwardMessage ? "forwardMessage_pad" : ""}`}
                                        position={"left"}
                                        type={"text"}
                                        text={
                                          editingMessageId ===
                                          index.toString() ? (
                                            <div
                                              ref={divRef}
                                              style={{
                                                cursor: "pointer",
                                                border: "none",
                                                width: "100%",
                                                outline: "none",
                                              }}
                                              contentEditable={true}
                                              onKeyPress={(event) =>
                                                handleKeyPress(event, message)
                                              }
                                              onInput={handleContentChange}
                                            >
                                              {editedContent}
                                              {/* SavechangesA */}
                                              {editedContent && editingMessageId ===
                                          index.toString() ? (
                                            <Grid gap={1} style={{marginLeft: "134px"}}>
                                            <CloseIcon onClick={handleClose} >Close</CloseIcon>
                                                <FileDownloadDoneIcon style={{marginLeft: "20px"}} onClick={(event) =>handleTick(event, message)}>Save changes</FileDownloadDoneIcon>
                                                </Grid>
                                              ) : null}
                                              {opens?
                                               <Dialog
                                               open={opens}
                                               onClose={handleCloseEdit}
                                               aria-labelledby="alert-dialog-title"
                                               aria-describedby="alert-dialog-description"
                                             >
                                               <DialogTitle id="alert-dialog-title">
                                               Are you sure want edit?
                                               </DialogTitle>
                                               <DialogActions>
                                                 <Button onClick={handleCloseEdit}>Yes</Button>
                                                 <Button onClick={handleCloseEditCheck} autoFocus>
                                                   No
                                                 </Button>
                                               </DialogActions>
                                             </Dialog>:null}
                                            </div>
                                          ) : (
                                            <>
                                              {message?.message &&
                                                decrypt(
                                                  message?.message?.content
                                                )}
                                              {message?.type === "IMAGE" && (
                                                <img
                                                  src={
                                                    message?.message?.content
                                                  }
                                                  alt="attachment"
                                                  className="message-image"
                                                  style={{
                                                    width: "100px",
                                                    borderRadius: "4px",
                                                    height: "80px",
                                                  }}
                                                  // onClick={() => setImagePreview(message?.message?.content)}
                                                  onClick={() =>
                                                    handleToggleMediaView(
                                                      message?.message?.content,
                                                      message?.type
                                                    )
                                                  }
                                                />
                                              )}
                                              {message?.type == "DOCUMENT" && (
                                                <Grid
                                                  container
                                                  onClick={() =>
                                                    handleToggleMediaView(
                                                      message?.message?.content,
                                                      message?.type
                                                    )
                                                  }
                                                  sx={{
                                                    padding: "10px 2px",
                                                    backgroundColor: "#F6F6F6",
                                                    borderRadius: "4px",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <Grid
                                                    item
                                                    xs={1.3}
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Grid
                                                      item
                                                      sx={{
                                                        width: "40px",
                                                        height: "40px",
                                                        backgroundColor:
                                                          "white",
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                        borderRadius: "4px",
                                                      }}
                                                    >
                                                      <img
                                                        src={`..${subPath}/assets/images/images/File_icon.png`}
                                                        alt="File_icon"
                                                        style={{
                                                          width: "33px",
                                                          height: "33px",
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={10}
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Typography className="Doc_lable">
                                                      {message?.message?.name}
                                                    </Typography>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    xs={0.7}
                                                    sx={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <img
                                                      src={`..${subPath}/assets/images/images/forward_chat.png`}
                                                      alt="forward_chat"
                                                      style={{
                                                        width: "30px",
                                                        height: "30px",
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              )}
                                              {message?.type === "AUDIO" && (
                                                <>
                                                  <Grid
                                                    container
                                                    onClick={() => handleAudioview(message?.message?.id)}
                                                    sx={{
                                                      padding: "10px 2px",
                                                      backgroundColor:
                                                        "#F6F6F6",
                                                      borderRadius: "4px",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <Grid
                                                      item
                                                      xs={1.3}
                                                      sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <img
                                                        src={`..${subPath}/assets/images/images/Music_icon.png`}
                                                        alt="Music_icon"
                                                        style={{
                                                          width: "33px",
                                                          height: "33px",
                                                        }}
                                                      />
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={10}
                                                      sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <Typography className="audio_lable">
                                                        {message?.message?.name}
                                                      </Typography>
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      xs={0.7}
                                                      sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <img
                                                        src={`..${subPath}/assets/images/images/forward_chat.png`}
                                                        alt="forward_chat"
                                                        style={{
                                                          width: "30px",
                                                          height: "30px",
                                                        }}
                                                      />
                                                    </Grid>
                                                  </Grid>
                                                  {audioOpen && audioview && audioview === message?.message?.id && (
                                                    <Grid
                                                    item
                                                      className="audio_view"
                                                      sx={{
                                                        mt: 1,
                                                      }}
                                                    >
                                                      <Grid container >
                                                      <Grid item xs={12} 
                                                        >
                                                        <AudioPlayer
                                                          ref={playerRef}
                                                          showFilledProgress={
                                                            true
                                                          }
                                                          src={
                                                            message?.message
                                                              ?.content
                                                          }
                                                          customIcons={{
                                                            forward: (
                                                              <CustomForwardButton />
                                                            ),
                                                            rewind: (
                                                              <CustomRewindButton />
                                                            ),
                                                            play: (
                                                              <CustomPlayButton />
                                                            ),
                                                            pause: (
                                                              <CustomPauseButton />
                                                            ),
                                                          }}
                                                          onPlay={(e) =>
                                                            console.log(
                                                              "onPlay"
                                                            )
                                                          }
                                                          onListen={
                                                            handleListen
                                                          }
                                                        />
                                                        </Grid>
                                                        </Grid>
                                                        <Grid
                                                        xs={12}
                                                        sx={{padding:"10px 10px"}}
                                                          item
                                                        >
                                                          <input
                                                            type="range"
                                                            className="progress_bar"
                                                            min={0}
                                                            max={duration}
                                                            value={currentTime}
                                                            onChange={(e) =>
                                                              handleSeek(
                                                                e.target.value
                                                              )
                                                            }
                                                          />
                                                        </Grid>
                                                        <Grid container sx={{padding:"0px 10px"}}>
                                                           <Grid item  xs={6}>
                                                            {formatTime(
                                                              currentTime
                                                            )}
                                                           </Grid>
                                                           <Grid item xs={6} sx={{display:"flex",justifyContent:"end",alignItems:"center"}}>
                                                           {formatTime(
                                                              duration
                                                            )}
                                                           </Grid>
                                                        </Grid>

                                                      {/* <div className="audio">
                                                        <audio controls>
                                                          <source
                                                            src={
                                                              message?.message
                                                                ?.content
                                                            }
                                                            type="audio/mpeg"
                                                          />
                                                          Your browser does not
                                                          support the audio
                                                          element.
                                                        </audio>
                                                      </div> */}
                                                    </Grid>
                                                  )}
                                                </>
                                              )}

                                              {message?.type === "VIDEO" && (
                                                <div
                                                  onClick={() =>
                                                    handleToggleMediaView(
                                                      message?.message?.content,
                                                      message?.type
                                                    )
                                                  }
                                                >
                                                  <div className="video">
                                                    <div
                                                      className="video_preview"
                                                      style={{
                                                        backgroundImage:
                                                          "url(https://raw.githubusercontent.com/Ahmetaksungur/twitter-video-player-clone/master/92456705_506989316849451_7379405183454806542_n.jpg)",
                                                      }}
                                                    >
                                                      <button
                                                        className="videostart_btn"
                                                        onClick={play}
                                                      >
                                                        <img
                                                          src={`..${subPath}/assets/images/images/play_icon.png`}
                                                          alt="play"
                                                          style={{
                                                            width: "50px",
                                                            height: "50px",
                                                          }}
                                                        />
                                                      </button>
                                                    </div>
                                                  </div>
                                                 <Grid item mt={1} xs={10}>
                                                  <Typography title={message?.message?.name} className="videoChat_lable">
                                                  {message?.message?.name}
                                                  </Typography>
                                                  </Grid> 
                                                </div>
                                              )}
                                              {/* {message?.type.startsWith("video/") && (
                                                <div onClick={() => handleToggleMediaView(message?.message?.content,message?.type)}>
                                                  <video
                                                    width="100"
                                                    height="80"
                                                    controls
                                                  >
                                                    <source src={message?.message?.content} type={message?.type} />
                                                    Your browser does not support the video tag.
                                                  </video>
                                                </div>
                                              )} */}
                                            </>
                                          )
                                        }
                                        title={
                                          <Grid
                                            container
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Grid
                                              item
                                              xs={
                                                message?.forwardMessage === true
                                                  ? 8
                                                  : 4
                                              }
                                              md={
                                                message?.forwardMessage === true
                                                  ? 4
                                                  : 8
                                              }
                                              lg={
                                                message?.forwardMessage === true
                                                  ? 5
                                                  : 8
                                              }
                                              className="sender_lable"
                                            >
                                              {message.senderName
                                                .charAt(0)
                                                .toUpperCase() +
                                                message.senderName.slice(1)}
                                            </Grid>
                                            <Grid
                                              item
                                              xs={
                                                message?.forwardMessage === true
                                                  ? 4
                                                  : 8
                                              }
                                              md={
                                                message?.forwardMessage === true
                                                  ? 8
                                                  : 4
                                              }
                                              lg={
                                                message?.forwardMessage === true
                                                  ? 7
                                                  : 4
                                              }
                                              sx={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                              }}
                                            >
                                              {message?.forwardMessage ===
                                              true ? (
                                                <Grid
                                                  item
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Grid item mr={1.5}>
                                                    <img
                                                      src={`..${subPath}/assets/images/images/Group_forward.png`}
                                                       alt="Group_forward icon"
                                                      style={{
                                                        width: "14px",
                                                        height: "10px",
                                                      }}
                                                    />
                                                  </Grid>
                                                  <Grid item mr={1.5}>
                                                    <Typography
                                                      sx={{
                                                        fontFamily:
                                                          "Montserrat",
                                                        fontSize: "11px",
                                                        fontWeight: "700",
                                                        color: "#848484",
                                                      }}
                                                    >
                                                      Forwarded
                                                    </Typography>
                                                  </Grid>
                                                  <Grid
                                                    item
                                                    mr={1.5}
                                                    sx={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <img
                                                      src={`..${subPath}/assets/images/images/Ellipse_Round.png`}
                                                       alt="Ellipse Round icon"
                                                      style={{
                                                        width: "6px",
                                                        height: "6px",
                                                      }}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              ) : (
                                                ""
                                              )}
                                              <Grid
                                                item
                                                sx={{
                                                  display: "flex",
                                                  color: "#414B54",
                                                  fontSize: "12px",
                                                  fontWeight: "400",
                                                  fontFamily: "Montserrat",
                                                }}
                                              >
                                                {moment(
                                                  message.message.sendAt
                                                ).format("hh:mm A")}
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        }
                                        focus={true}
                                        titleColor={"#000000"}
                                        replyButton={false}
                                        removeButton={false}
                                        date={new Date(message.message.sendAt)}
                                        forwarded={message.forwardMessage}
                                        status={"sent"}
                                        notch={true}
                                        retracted={false}
                                      />
                                    </Typography>
                                    {hoveredMessageReply === index && (
                                      <ReplymsgTooltip
                                      unPinnedMessage={unPinnedMessage}
                                      recep={message?.senderId}
                                        cahtRecep={
                                          chatDetails?.chatDetails?.senderId
                                        }
                                        forwardMessage={() =>
                                          handleForwardMessageShow(message)
                                        }
                                        DeleteMessage={() =>
                                          handleDeleteMessage(message)
                                        }
                                        PinClick={() => handlePinShow(message)}
                                        ReplyMessage={() =>
                                          handleReplyClick(index)
                                        }
                                        MouseEnter={() =>
                                          setHoveredMessageReply(index)
                                        }
                                        EditClick={() =>
                                          handleEditClick(
                                            index.toString(),

                                            message?.message &&
                                              decrypt(message?.message?.content)
                                          )
                                        }
                                      />
                                    )}
                                  </Box>
                                </Grid>
                              </Box>):( 
                               ""
                            )}
                             </>
                            )}
                            <div>
                              {isReplying && replyingIndex === index && (
                                <ChatReply
                                  chatDetails={chatDetails}
                                  message={message}
                                  index={index}
                                  isProfileShow={isProfileShow}
                                  isForwardMessage={isForwardMessageShow}
                                  isReplying={isReplying}
                                  handelReplyClose={() => handelReplyShow()}
                                  userData={userData}
                                />
                              )}
                            </div>
                          </>
                        );
                      }
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            {/* <Box key={index} onMouseEnter={() => setHoveredMessageId(index)} onMouseLeave={() => setHoveredMessageId(null)} style={{ position: "relative" }}>
                <MessageBox
              //     position={message.senderId === userIdSend ? 'left' : 'right'}
              //     type={'text'}
              //     text={decrypt(JSON.parse(message.message).content)}
              //     title={message.senderName}
              //     date={new Date(JSON.parse(message.message).sendAt)}
              //     id={index.toString()} // Ensure a unique id if needed
              //     className="chatbox_pad"
              //     focus={true}
              //     titleColor={"#000000"}
              //     replyButton={false}
              //     removeButton={false}
              //     forwarded={false}
              //     status={"sent"}
              //     notch={true}
              //     retracted={false}
              //   />
              // </Box> */}

            <Grid item sx={{ position: "sticky", right: 0, top: "100px" }}>
              {isProfileShow && (
                <ChatProfile chatData={chatDetails?.chatDetails} 
                handleProfileClick={handleProfileClick}
                />
              )}
              {isForwardMessageShow && (
                <ForwardMessageView
                  isPinShow={isPinShow}
                  forwardMsg={forwardMsg}
                  chatData={chatDetails?.chatDetails}
                  forwardMessage={handleCloseForwardMessage}
                />
              )}
            </Grid>
            <Grid
              container
              className={
                isProfileShow || isForwardMessageShow
                  ? "profile_show_container"
                  : "profile_hide_container"
              }
              sx={{
                height: "50px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #00000026",
                borderLeft: "0px solid",
                zIndex: 1,
                position: "fixed",
                bottom: 0,
                width: () => ({
                  xs: isProfileShow || isForwardMessageShow ? "47.55%" : "70%",
                  sm: isProfileShow || isForwardMessageShow ? "49.55%" : "72%",

                  md: isProfileShow || isForwardMessageShow ? "50.%" : "73%",
                  lg: isProfileShow || isForwardMessageShow ? "51.3%" : "74%",
                  xl: isProfileShow || isForwardMessageShow ? "52%" : "75%",
                }),
              }}
            >
              <Grid
                item
                xs={1}
                md={isProfileShow || isForwardMessageShow ? 0.7 : 0.5}
                lg={isProfileShow || isForwardMessageShow ? 0.7 : 0.5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <img
                  src={`..${subPath}/assets/images/images/element-plus.png`}
                  alt="plus"
                  style={{ width: "24px", height: "24px", cursor: "pointer" }}
                  onClick={handleImageClick}
                />
                <input
                  type="file"
                  ref={fileInputRef}
                  multiple
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </Grid>
              <Grid
                item
                xs={10}
                md={isProfileShow || isForwardMessageShow ? 10.7 : 10.7}
                lg={isProfileShow || isForwardMessageShow ? 10.7 : 10.8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
                >
                  <IconButton onClick={handleOpenEmojiPicker}>
              <EmojiEmotionsIcon />
            </IconButton>
                
                 <Box sx={{ display: 'flex', alignItems: 'center', width:'100%' }}>
               
           
                 <InputBase
                     placeholder={`Message ${chatDetails?.chatDetails?.recipientName}`}
                    className="ml-15 input_search"
                    sx={{
                      background: "#fffff",
                      color: "#708090",
                      boxSizing: "border-box",
                      fontStyle: "italic",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                    id="searchText"
                    autoComplete="off"
                    value={messageInput}
                    onChange={handleChangeInput}
                    onKeyDown={handleKeyDown}
      />

      <Popover
        id={emojid }
        open={openemoji}
        anchorEl={emoji}
        onClose={handleCloseEmojiPicker}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <EmojiPicker onEmojiClick={handleEmojiClick} />
      </Popover>
    </Box>
              </Grid>
              {showMentionList && messageInput.length>0 && (
        <ul
          style={{
            position: "absolute",
            top: "-259px", // Adjust based on input field position
            left: "10px",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            height: "247px",
            overflow: "scroll",
            borderRadius: "5px",
            listStyleType: "none",
            padding: "5px",
            zIndex: 1000,
          }}
        >
          {filteredMembers.map((member, index) => (
            <li
              key={index}
              onClick={() => handleMentionClick(member)}
              style={{ cursor: "pointer", padding: "5px 10px" }}
            >
              {member}
            </li>
          ))}
        </ul>
      )}
              <Grid
                item
                xs={1}
                md={isProfileShow || isForwardMessageShow ? 0.6 : 0.5}
                lg={isProfileShow || isForwardMessageShow ? 0.6 : 0.5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <img
                  src={`..${subPath}/assets/images/images/send_chat.png`}
                  alt="plus"
                  style={{ width: "24px", height: "24px" }}
                  onClick={sendMessage}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </Grid>
  );
  
};

export default ChatView;
