import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link, Path, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../Constants/hooks";
import { setItemName, setSearchValue } from "../Redux/Search/action";
import { subPath } from "../Constants/Global";

const CommonSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  // State to track the active menu item
  const [activeItem, setActiveItem] = useState(location.pathname);

  const handleLogoClick = () => {
    navigate(`/landingAssetView`);
    dispatch(setSearchValue(""));
    dispatch(setItemName(""));
  };

  const handleMenuItemClick = (path: string) => {
    setActiveItem(path);
    navigate(path);
  };

  const getMenuItemClass = (path: string) => {
    return activeItem === path ? "active_side_com" : "disActive_side_com";
  };

  const chatListData = useAppSelector(
    (state: any) => state?.chatListData?.chatList
  );
  console.log("chatListData.length",chatListData);
  const [unreadCounts, setUnreadCounts] = useState({});

  useEffect(() => {
    if (chatListData?.chatMembersList) {
      const newUnreadCounts = {};

      chatListData.chatMembersList.forEach(member => {
        const userId = member.id; // Assuming each member has a unique ID
        const currentCount = member.lastMessage?.unreadMessageCount || 0;

        if (currentCount > 0) {
          // Initialize or update unread count by incrementing
          newUnreadCounts[userId] = (newUnreadCounts[userId] || 0) + 1;
        }
      });

      setUnreadCounts(newUnreadCounts);
    }
  }, [chatListData]);
  return (
    <Sidebar className="sidebar_chart">
      <Menu
        className="sidebar_image"
        menuItemStyles={{
          button: {
            [`&.active`]: {
              backgroundColor: "#13395e",
              color: "#b6c8d9",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            margin: "25px 0",
          },
        }}
      >
        <img
          src={`..${subPath}/assets/images/new_logo.png`}
          alt=""
          className="img-fluid logo"
          style={{
            cursor: "pointer",
            width: "34px",
            height: "48px",
            display: "block",
            margin: "0 auto",
            marginTop: "30px",
            marginBottom: "43px",
          }}
          onClick={handleLogoClick}
        />
        <MenuItem
          style={{ fontSize: "12px", marginRight: "0px", color: "#aaaaaa" }}
          onClick={() => handleMenuItemClick("/landingAssetView")}
          className={getMenuItemClass("/landingAssetView")}
          icon={
            <img
              src={`..${subPath}/assets/images/images/${
                activeItem === "/landingAssetView"
                  ? "Asset_Icon_chat.png"
                  : "Asset_Icon_chat.png"
              }`}
              alt="Assets"
              style={{ width: "24px", height: "24px" }}
            />
          }
        >
          Assets
        </MenuItem>
        {Object.keys(unreadCounts).map(userId => (
              <span style={{ position: 'absolute',
              top: '185px', // Adjust position vertically
              right: '7px', // Adjust position horizontally
              backgroundColor: 'red',
              color: 'white',
              borderRadius: '50%',
              padding: '3px 8px',
              fontSize: '12px',
              fontWeight: 'bold'}}>{unreadCounts[userId]}</span>))}
  
        <MenuItem
          style={{
            fontSize: "12px",
          }}
          onClick={() => handleMenuItemClick("/directMessage")}
          className={getMenuItemClass("/directMessage")}
          icon={
            <img
              src={`..${subPath}/assets/images/images/${
                activeItem === "/directMessage"
                  ? "message_Chat.png"
                  : "Direct_Messages_Icon.png"
              }`}
              alt="DM"
              style={{
                width: "24px",
                height: "24px",
              }}
            />
          }
        >
          DM's
        </MenuItem>
        <MenuItem
          style={{ fontSize: "12px" }}
          onClick={() => handleMenuItemClick("/circleMessage")}
          className={getMenuItemClass("/circleMessage")}
          icon={
            <img
              src={`..${subPath}/assets/images/images/${
                activeItem === "/circleMessage"
                  ? "Circle_Icon_act.png"
                  : "Circle _Icon_chat.png"
              }`}
              alt="Circle"
              style={{ width: "24px", height: "24px" }}
            />
          }
        >
          Circle's
        </MenuItem>

      </Menu>
    </Sidebar>
  );
};

export default CommonSidebar;
