import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import React, { useState, useRef } from "react";
import { subPath } from "../../../../Constants/Global";
import { useAppDispatch, useAppSelector } from "../../../../Constants/hooks";
import PreviewAttachment from "../ChatMessage/PreviewAttachment";
import ThumbnailCarousel from "../ChatMessage/ThumbnailCarousel";
import { isMediaShow } from "../../../../Redux/chatList/action";
import { Page, pdfjs, Document } from "react-pdf";
import Modal from "../../Modal";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();
interface ImageFile {
  src: string;
  alt: string;
}
const ChatMediaView = (chatDetails) => {
  
  console.log("chatDetails. ==> ", chatDetails);

  type ChatMediaViewProps = {
    imageUrl: string;
    onSend: () => void;
  };

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [imagePreview, setImagePreview] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState<File | null>(null);
  const [files, setFiles] = useState([]);
  const dispatch = useAppDispatch();

  const scroll = (direction: string) => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const scrollAmount =
        direction === "left" ? -container.clientWidth : container.clientWidth;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  // const handleThumbnailClick = (file: File) => {
  //   if (file.type.startsWith('image/')) {
  //     setSelectedImage(file);
  //   } else if (file.type === 'application/pdf') {
  //     setSelectedDocument(file);
  //   } else if (file.type.startsWith('video/')) {
  //     setSelectedVideo(file);
  //   }
  // };

  const onImageSelected = () => {
    const formData = new FormData();

    formData.append('senderId', chatDetails.chatDetails?.senderId || '');
    formData.append('senderName', chatDetails?.chatDetails?.senderName || '');
    formData.append('recipientId', chatDetails?.chatDetails?.recipientId || '');
    formData.append('recipientName', chatDetails?.chatDetails?.recipientName || '');
    formData.append('department', '');
    formData.append('designation', '');
    formData.append('groupId', '');
    formData.append('groupName', '');
    formData.append('groupProfilePicture', '');
    formData.append('senderProfilePicture', '');
    formData.append('colorCode', '');
    formData.append('deviceToken', '');
    formData.append('replyTo', '');
    formData.append('created At', '');
    files.forEach((fileObj: { file: File }, index) => {
      const file = fileObj.file;
      if (file.type.startsWith("image/")) {
        formData.append(`images[${index}].media`, file);
        formData.append(`images[${index}].id`, `file_${index}`);
        formData.append(`images[${index}].caption`, "");
      } else if (file.type === "application/pdf") {
        formData.append(`documents[${index}].media`, file);
        formData.append(`documents[${index}].id`, `file_${index}`);
        formData.append(`documents[${index}].caption`, "");
      } else if (file.type.startsWith("video/")) {
        formData.append(`videos[${index}].media`, file);
        formData.append(`videos[${index}].id`, `file_${index}`);
        formData.append(`videos[${index}].caption`, "");
      } else if (file.type.startsWith("audio/")) {
        formData.append(`audios[${index}].media`, file);
        formData.append(`audios[${index}].id`, `file_${index}`);
        formData.append(`audios[${index}].caption`, "");
      }
    });

    // Add similar blocks for audios if needed

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
  };

  const handleViewFile = (index: number) => {
    setCurrentIndex(index);
  };

  const handleCloseView = () => {
    setCurrentIndex(null);
  };

  const handlePrevious = () => {
    if (currentIndex !== null && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex !== null && currentIndex < files.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  
  const handlePreviewClick = () => {
    // <Document
    //   file={"https://packagingapp.blob.core.windows.net/trove/20240820145903_20240820143120_20240820110355_20240730145827_20240730125351_20240730104803_20240729161634_20240729090455_20240729060454_20240729051609_20240729050509_20240726100354_20240726065237_20240725152112_20240724102323_%285%29.pdf?sig=tKFO4VX6yFJ0pDCrMc2YcaD38WNHti%2BzuRDV5szPkD8%3D&se=2025-08-20T09%3A29%3A04Z&sv=2019-02-02&sp=rl&sr=c&x-ms-blob-content-disposition=inline"}
    //   >
    // </Document>;
    <iframe src = {imageURL} width ="200px" height= "100px"/>

  };

  const isMediaView = useAppSelector(
    (state: any) => state?.chatListData?.isMediaView
  );
  const imageURL = useAppSelector(
    (state: any) => state?.chatListData?.imageURL
  );
  const fileType = useAppSelector(
    (state: any) => state?.chatListData?.fileType
  );
 
  
  const video = useAppSelector((state: any) => state?.chatListData);
  console.log("imageURL-----------", imageURL);

  const handleCloseMedia = () => {
    dispatch(
      isMediaShow({ isMediaView: !isMediaView, imageURL: "", fileType: "" })
    );
  };
  const handleToggleMediaView = (image) => {
    setSelectedImage(image);
  };

  function handleThumbnailClick(): void {
    throw new Error("Function not implemented.");
  }
console.log("imageURL-------->",imageURL)
  return (
    <div>
      <Box>
        <Grid
          container
          sx={{
            background: "white",
            height: "50px",
            borderBottom: "1px solid #E5E5E5",
            alignItems: "center",
          }}
        >
          <Grid
            item
            sm={0.5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              className="prev-icon back_button_media"
              onClick={handleCloseMedia}
              sx={{ padding: 0 }}
            >
              <img
                src={`..${subPath}/assets/images/images/Back_Button.png`}
                alt="back Arrow"
                style={{ width: "25px", height: "25px", cursor: "pointer" }}
              />
            </Button>
          </Grid>
          <Grid item sx={{display:'flex', alignItems:'center',}}>
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: 500,
                color: "#000000",
          
                         }}
            >
              {fileType === "IMAGE" ? "Image.png" : fileType === "DOCUMENT" ? "Document.pdf" : "Video.mp4"}
            </Typography>
            </Grid>
        </Grid>

        <Grid item sx={{ backgroundColor: "#E5E5E5",  height: fileType === "VIDEO" ? "100vh" : "80vh",}}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: fileType === "VIDEO" ? "start" :"start",
                height: fileType === "VIDEO" ? "100vh" : "80vh",
                paddingTop: fileType === "VIDEO" ? "30px" : "20px",
              }}
            >
              {fileType === "IMAGE" && (
                <Grid>
                  <img
                    src={imageURL}
                    alt="Preview"
                    style={{
                      height: "400px",
                      maxHeight: "600px",
                      maxWidth: "400px",
                    }}
                    onClick={() => handleToggleMediaView(selectedImage)}
                  />
                </Grid>
              )}
              {fileType === "DOCUMENT" && (
                <Grid item xs={8} sx={{height:{md:"60vh", lg:"60vh", sm:"60vh", xl:"70vh"}}}>
                  {/* <Document
                    file={imageURL}
                    className="document-preview doc_preview"
                    // onClick={() => handleToggleMediaView(file.previewUrl)}
                  >
                    <Page pageNumber={1} />
                  </Document> */}
                   <iframe src = {imageURL} width ="100%" height={"100%"} />
                </Grid>
              )}
              {fileType === "VIDEO" && (
                <Grid>
                  <video
                    src={imageURL}
                    controls
                    style={{
                      maxWidth: "850px",
                      maxHeight: "450px",
                    }}
                  />
                </Grid>
              )}
            </Grid>
            {selectedImage && (
              <PreviewAttachment
                image={selectedImage}
                onImageSelected={onImageSelected}
              />
            )}
          </Grid>

    

          {/* <Grid item sx={{ backgroundColor: "#E5E5E5", height: "80vh " }}>
            {selectedImage && (
              <PreviewAttachment
                image={selectedImage}
                onImageSelected={onImageSelected}
              />
            )}
          </Grid> */}
  { fileType === "VIDEO" ? (<></>):(
          <Grid
            item
            sx={{
              position: "fixed",
              bottom: "0px",
              width: "100%",
              height: "80px",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
         
            <ThumbnailCarousel
              images={files}
              onThumbnailClick={handleThumbnailClick}
            />
          </Grid>
            )}

      </Box>
    </div>
  );
};

export default ChatMediaView;

