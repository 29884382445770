// action.tsx
import { Dispatch } from "redux";
import ChatAxios from "../../Constants/ChatAxios";
import axios from "axios";


const api = ChatAxios();

export const createCircleGroup = (Data: any) => {
    return async () => {
        const response = await api.post(`/group/createGroup`, Data, {
            headers: {
                'Content-Type': 'multipart/form-data', // Set the correct content type
            }
        });
        return response;    
    };
}


export const muteGroupChat = (Data: any) => {
    return async () => {
        const response = await api.post(`/muteRecipientsOrGroupsForUser`, Data,
            {
                headers: {
                    'Content-Type': 'application/json'

                }
            }
        );
        return response;
    };
}

export const unMuteGroupChat = (Data: any) => {
    return async () => {
        const response = await api.post(`/unmuteRecipientsOrGroupsForUser`, Data,
            {
                headers: {
                    'Content-Type': 'application/json'

                }
            }
        );
        return response;
    };
}
export const sendMedia = (formData: FormData) => {
    return async (dispatch: Dispatch) => {
        try {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            const response = await axios.post('https://trove-dev-gateway.citpl.info/chat-module/media/post', formData, config);
            // console.log(response)
            
            dispatch({
                type: '',
                payload: response.data.data,
            });

            return response;
        } catch (error) {
            console.error('Error:', error);
            dispatch({
                type: 'SEND_MEDIA_FAILURE',
                payload: error,
            });
            throw error;
        }
    };
}
//group attachments
export const sendGroupChatMedia = (formData: FormData) => {
    
    return async (dispatch: Dispatch) => {
        try {
            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };
 
            const response = await axios.post('https://trove-dev-gateway.citpl.info/chat-module/media/post', formData, config);
 
            dispatch({
                type: 'SEND_GROUP_CHAT_MEDIA_SUCCESS',
                payload: response.data.data,
            });
 
            return response;
        } catch (error) {
            console.error('Error:', error);
            dispatch({
                type: 'SEND_GROUP_CHAT_MEDIA_FAILURE',
                payload: error,
            });
            throw error;
        }
    };
  };
 
