import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { decodeToken, subPath } from "../../../../Constants/Global";
import ProfileMember from "./CreateCircle/ProfileMember";
import AddMember from "./CreateCircle/AddMember";
import { useAppDispatch, useAppSelector } from "../../../../Constants/hooks";
import { getGroupDetails } from "../../../../Redux/GroupChat/action";

interface CircleProfileViewProps {
  onBack: () => void;
  groupDetails : any;
}

const CircleProfileView: React.FC<CircleProfileViewProps> = ({ onBack,groupDetails }) => {
  const userData: any = useAppSelector(
    (state: any) => state.chatListData.userInfo
  );
  const chatMessage: any = useAppSelector(
    (state: any) => state?.groupChat?.messages
  );
  const handleback = () => {
    onBack();
    console.log("onback1");
  };
  const [imageSrc, setImageSrc] = useState(
    "https://images.unsplash.com/photo-1620163280053-68782bd98475?q=80&w=2865&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  );
  const [showToast, setShowToast] = useState(false);

  const handleImageChange = (e: any) => {
    if (e.target.files.length) {
      const src = URL.createObjectURL(e.target.files[0]);
      setImageSrc(src);
      displayToast();
    }
  };

  const displayToast = () => {
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  const userimg = [
    { src: "/assets/images/images/view_img1.png" },
    { src: "/assets/images/images/view_img2.png" },
    { src: "/assets/images/images/user_profile_img.png" },
    { src: "/assets/images/images/view_img2.png" },
    { src: "/assets/images/images/view_img2.png" },
    { src: "/assets/images/images/view_img2.png" },
    { src: "/assets/images/images/view_img2.png" },
  ];

  const filedata = [
    {
      filesrc: "/assets/images/images/File_icon.png",
      fileName: "CITPL Spotlight - Dec 2024.pdf",
      fileSubName: "635 KB pdf",
      arrowSrc: "/assets/images/images/lucide_forward.png",
    },
    {
      filesrc: "/assets/images/images/Music_icon.png",
      fileName: "CITPL Spotlight - Dec 2024.mp3",
      fileSubName: "00 : 35",
      arrowSrc: "/assets/images/images/lucide_forward.png",
    },
  ];

  const [isAddMember, setIsAddMember] = useState(false);
  const handleAddMember = () => {
    setIsAddMember(!isAddMember);
  };

  const dispatch = useAppDispatch();





  const Grouplist = useAppSelector(
    (state: any) => state?.groupChat?.groupDetails || []
  );
  const groupChatList = useAppSelector((state: any) => state.groupChat.groups);
  console.log(groupChatList, "groupChatList");
console.log(".............group",Grouplist);
  const arr:any = [];
for(let i=0;i<groupChatList.chatGroupsList.length;i++){
  if(groupChatList.chatGroupsList[i].groupId == groupDetails.groupId){
    arr.push(groupChatList.chatGroupsList[i])
  }
}
console.log("arrrrrrrrrrr",arr);
  return (
    <Grid>
      <Grid
        item
        xs={2.48}
        md={2.5}
        lg={2.54}
        sx={{
          position: "fixed",
          right: 0,
          width: "100%",
          top: "125px",
          height: "100vh",
          backgroundColor: "white",
        }}
      >
        {!isAddMember ? (
          <Box
            sx={{
              backgroundColor: "white",
              width: "100%",
              height: "80%",
              overflowY: "auto",
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "end",
                marginTop: "10px",
                paddingRight: "13px",
              }}
            >
              <img
                onClick={handleback}
                src={`..${subPath}/assets/images/images/Cancle_icon.png`}
                alt="Cancel"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </Grid>
            <Grid container sx={{ display: "flex", justifyContent: "center" }}>
              <Grid item>
                <label
                  htmlFor="file-input"
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <Grid item>
                    <input
                      id="file-input"
                      type="file"
                      className="inputBox_imgUpload"
                      onChange={handleImageChange}
                    />
                    <Avatar
                      className="image_upload"
                      alt="Upload Preview"
                      sx={{
                        borderRadius: "25%",
                        height: "100px",
                        width: "100px",
                      }}
                           src={arr[0]?.groupProfilePicture || undefined}
                    >
                      {!arr[0]?.groupProfilePicture &&
                      <Grid item sx={{fontSize:'35px' , fontWeight:'700'}}>
                       { arr[0]?.groupName?.charAt(0)?.toUpperCase()}
                        </Grid>}
                    </Avatar>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      border: " 1.25px solid #00000066",
                      backgroundColor: "#FFFFFF",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "-12px",
                      marginLeft: "-21px",
                      zIndex: 1,
                    }}
                  >
                    <img
                      src={`..${subPath}/assets/images/images/camera_img.png`}
                      alt="Search"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </Grid>
                </label>
                {showToast && (
                  <div className="toast">Image uploaded successfully!</div>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  className="profile_icon"
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                ></IconButton>
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#000000",
                    fontFamily: "Montserrat",
                    textAlign: "center",
                    lineHeight: "17.07px",
                  }}
                >
                  <Grid
                    container
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={10}
                      lg={10}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      {arr[0].groupName}
                    </Grid>
                    <Grid
                      item
                      md={2}
                      lg={2}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <img
                        src={`..${subPath}/assets/images/images/edit_ProfileName.png`}
                        alt="Mute Chat Icon"
                        width="20"
                        height="20"
                      />
                    </Grid>
                  </Grid>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: "14px",
                      paddingTop: "10px",
                      textAlign: "center",
                      color: "#00000099",
                      fontWeight: 400,
                      fontFamily: "Montserrat",
                      lineHeight: "17.07px",
                    }}
                  >
                    {/* {chatData.chatData.recipientDesignation} &nbsp; / &nbsp;
                    {chatData.chatData.recipientDepartment} */}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{
                      fontSize: "12px",
                      paddingTop: "10px",
                      textAlign: "center",
                      color: "#708090",
                      fontWeight: 400,
                      fontFamily: "Montserrat",
                      lineHeight: "17.07px",
                    }}
                  >
                    {arr[0].members.length} &nbsp; Members
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ padding: "20px 10px 0px 10px" }}>
              <Grid container sx={{ paddingTop: "20px " }}>
                <Grid item sm={10}>
                  <Typography className="Members_lable_Circle">Members</Typography>
                </Grid>
                <Grid
                  item
                  sm={2}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <img
                    onClick={handleAddMember}
                    src={`..${subPath}/assets/images/images/profile_add_circle.png`}
                    alt="Mute Chat Icon"
                    width="20"
                    height="20"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sx={{ marginTop: "10px", }}
              >
                <ProfileMember arr={arr[0].groupId}/>
              </Grid>
        
            </Box>
            <Divider sx={{ marginTop: "20px" }} />
            <Box sx={{ marginTop: "10px", padding: "10px" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography className="Media_lable_Chat">Media</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className="view_lable_chat">View All</Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                padding: "10px",
              }}
            >
              <Grid item sx={{ display: "flex", overflowX: "auto" }}>
                {userimg.map((item, index) => (
                  <Grid item xs={3} sx={{ marginRight: "10px" }} key={index}>
                    <Avatar
                      alt={`Profile ${index + 1}`}
                      style={{
                        borderRadius: "5%",
                        height: "50px",
                        width: "50px",
                      }}
                      src={item.src}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Divider />
            <Box sx={{ padding: "10px" }}>
              <Grid item className="Files_lable_chat">
                Files
              </Grid>
              <Box sx={{ marginTop: "12px" }}>
                {filedata.map((fileItems, index) => (
                  <Grid
                    container
                    sx={{
                      backgroundColor: "#F6F6F6",
                      height: "48px",
                      borderRadius: "4px",
                      marginTop: "8px",
                    }}
                  >
                    <Grid
                      item
                      xs={2.5}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Grid item className="file_icon_profile">
                        <Avatar
                          alt={`Profile ${index + 1}`}
                          style={{
                            borderRadius: "5%",
                            height: "24px",
                            width: "24px",
                          }}
                          src={fileItems.filesrc}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={7.7}>
                      <Grid item sx={{ marginTop: "5px" }}>
                        <Typography className="file_title_chat">
                          {fileItems.fileName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            color: "#708090",
                            fontSize: "12px",
                            fontFamily: "Montserrat",
                            fontWeight: 400,
                            lineHeight: "14.63px",
                          }}
                        >
                          {fileItems.fileSubName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={1.8}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        alt={`Profile ${index + 1}`}
                        style={{ width: "24px", height: "24px" }}
                        src={fileItems.arrowSrc}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Box>
          </Box>
        ) : (
          <AddMember handleAddMember={handleAddMember} />
        )}
      </Grid>
    </Grid>
  );
};
export default CircleProfileView;
