import { Dispatch } from "redux";
import ChatAxios from "../../Constants/ChatAxios";
import axios from "axios";
const api = ChatAxios();
const CHAT_VIEW = "CHAT_VIEW";
const CHAT_SEEN = "CHAT_SEEN";
const GROUP_SEEN = "GROUP_SEEN";
const PINNED_MESSAGE = "PINNED_MESSAGE";
interface Seen {
  senderId: string | null;
  recipientId: string | null;
}
interface Chat {
  senderId: string | null;
  recipientId: string | null;
  pageNo: number | null;
  rowPerPage: number | null;
}
interface DeleteChat {
  chatId: string | null;
}
interface DeleteMessage {
  type: string | null;
  action: string | null;
  senderId: string | null;
  recipientId: string | null;
  groupId: string | null;
  messageId: string | null;
  groupName: string | null;
  senderName: string | null;
  recipientName: string | null;
  message: {
    id: string | null;
    type: string | null;
    content: string | null;
    caption: string | null;
    name: string | null;
    size: number | null;
    sendAt: Date | null;
    deliveredAt: Date | null;
    seenAt: Date | null;
    createdAt: Date | null;
    updatedAt: Date | null;
  },
  replyToMessageId: string | null;
  replySenderId: string | null;
  senderProfilePicture: string | null;
  recipientProfilePicture: string | null;
  sendAt: Date | null;
  online: boolean | null;
  forwardMessage: boolean | null;

}
interface UpdateMessage {
  type: string | null;
  action: string | null;
  senderId: string | null;
  recipientId: string | null;
  groupId: string | null;
  messageId: string | null;
  groupName: string | null;
  senderName: string | null;
  recipientName: string | null;
  message: {
    id: string | null;
    type: string | null;
    content: string | null;
    caption: string | null;
    name: string | null;
    size: number | null;
    sendAt: Date | null;
    deliveredAt: Date | null;
    seenAt: Date | null;
    createdAt: Date | null;
    updatedAt: Date | null;
  },
  replyToMessageId: string | null;
  replySenderId: string | null;
  senderProfilePicture: string | null;
  recipientProfilePicture: string | null;
  sendAt: Date | null;
  online: boolean | null;
  forwardMessage: boolean | null;

}
interface SavePinnedMessage {
  userId: string | null;
  recipientId: string | null;
  groupId: string | null;
  messageId: string | null;
  message: object | null;
}
interface DeletePinnedMessage {
  pinnedMessageId: string | null;
}
interface MuteMessage{
  userId: string | null;
  recipientId: string | null;
  groupId: string | null;
}
interface UnMuteMessage{
  userId: string | null;
  recipientId: string | null;
  groupId: string | null;
}

interface pinnedMessage {
  userId: string | null;
  recipientId: string | null;
  groupId: string | null;
}
export interface ChatView {
  type: typeof CHAT_VIEW;
  payload: any;
}

export interface ChatSeen {
  type: typeof CHAT_SEEN;
  payload: any;
}
export interface GroupSeen {
  type: typeof GROUP_SEEN;
  payload: any;
}
export interface PinnedMessage {
  type: typeof PINNED_MESSAGE;
  payload: any;
}
export const getChatSeen = (chatDetails: Seen, signal: any) => {
  console.log("chatDetails ===> ", chatDetails);
  return (dispatch: Dispatch<ChatSeen>) => {
    api
      .get(`/privateChat/updateSeenAt`, { params: chatDetails, signal: signal?.signal, })
      .then((res) => {
        dispatch({
          type: CHAT_SEEN,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          // Handle other errors if needed
          console.error("Error:", error);
        }
      });
  };
};
export const getGroupSeen = (chatDetails: any, signal: any) => {
  return (dispatch: Dispatch<GroupSeen>) => {
    api
      .post(`/groupChat/updateSeenAt?userId=${chatDetails.userId}&groupId=${chatDetails.groupId}`)
      .then((res) => {
        dispatch({
          type: GROUP_SEEN,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          // Handle other errors if needed
          console.error("Error:", error);
        }
      });
  };
};

export const deletePrivateChat = (data: { senderId: string; chatId: string }) => {
  return async () => {
    const response = await api.post(`/privateChat/deletePrivateChat?senderId=${data.senderId}&chatId=${data.chatId}`, {

      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response;
  };
};
export const unPinChat = (data: any) => {
  return async () => {
    const response = await api.post(`/unpinRecipientsOrGroupsForUser`, data, {

      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response;
  };
};
export const leaveChat = (data: any) => {
  return async () => {
    const response = await api.post(`/group/leaveGroup?groupId=${data?.groupId}&userId=${data?.userId}`, data, {

      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response;
  };
};
export const pinRecipientsOrGroupsForUser = (data: any) => {
  return async () => {
    const response = await api.post(`/pinRecipientsOrGroupsForUser`, data, {

      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response;
  };
};
export const handleForwardMessage = (data: any) => {
  return async () => {
    const response = await api.post(`/message/forwardMessage`, data, {

      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response;
  };
};

export const getChatView = (chatDetails: Chat, signal: any) => {
  return (dispatch: Dispatch<ChatView>) => {
    api
      .get(`privateChat/getMessages`, { params: chatDetails, signal: signal?.signal, })
      .then((res) => {
        dispatch({
          type: CHAT_VIEW,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          // Handle other errors if needed
          console.error("Error:", error);
        }
      });
  };
};

export const deleteChatMessage = (deleteMessage: DeleteMessage) => {
  return async () => {
    const response = await api.post(`/message/deleteMessage`, deleteMessage,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response;
  };
}
//pinned Message
export const getPinnedMessage = (messageDetails: pinnedMessage, signal: any) => {
  return (dispatch: Dispatch<PinnedMessage>) => {
    api
      .get(`pinnedMessage/getPinnedMessage`, { params: messageDetails, signal: signal?.signal, })
      .then((res) => {
        dispatch({
          type: PINNED_MESSAGE,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          // Handle other errors if needed
          console.error("Error:", error);
        }
      });
  };
};
export const savePinnedMessage = (messagePinned: SavePinnedMessage) => {
  return async () => {
    const response = await api.post(`/pinnedMessage/savePinnedMessage`, messagePinned,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response;
  };
}
export const updateChatMessage = (updateMessage: UpdateMessage) => {
  return async () => {
    const response = await api.post(`/message/updateMessage`, updateMessage,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response;

  };
}
export const deletePinnedMessage = (deleteMessagePinned: DeletePinnedMessage) => {
  return async () => {
    const response = await api.post(`/pinnedMessage/deletePinnedMessage?pinnedMessageId=${deleteMessagePinned.pinnedMessageId}`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response;
  };
}

export const muteMessage =(muteMessage:MuteMessage)=>{
  return async ()=>{
    const response = await api.post(`muteRecipientsOrGroupsForUser?userId=${muteMessage.userId}&recipientId=${muteMessage.recipientId}&groupId=${muteMessage.groupId}`,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  return response;
  }
}

export const unMutechat =(unMuteMessage:UnMuteMessage)=>{
  return async ()=>{
    const response = await api.post(`unmuteRecipientsOrGroupsForUser?userId=${unMuteMessage.userId}&recipientId=${unMuteMessage.recipientId}&groupId=${unMuteMessage.groupId}`,
      {headers:{
        'Content-Type':'application/json'
      }}
    );
    return response;
  }
}


