import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import Sidebar from "./Layout/Sidebar";
import CommonSidebar from "./Layout/CommonSidebar";
import router from "./routes";
import { MyContextProvider } from "./Views/MyContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SuccessHandler from "./Views/Auth/SuccessHandler";
import { useLocation } from "react-router-dom";
import ChatHeader from "./Views/Components/Chat/ChatHeader/ChatHeader";
import PreviewAttachment from './PreviewAttachment';
import MediaView from './MediaView';
const Login = React.lazy(() => import("../src/Views/Auth/Login"));
const HeplandSupport = React.lazy(
  () => import("../src/Views/Auth/HelpSupport")
);
const NotFound = React.lazy(() => import("../src/Views/Auth/NotFound"));



interface LayoutProps {
  children: React.ReactNode; // Allow children to be passed
}

function App() {
  return (
    <MyContextProvider>
      
      <ToastContainer />
      <Router>
        {/* <HashRouter> */}
        <Suspense fallback={null}>
          <Routes>
            <Route
            
              path={"/successhanlder"}
              element={<SuccessHandler />}
            ></Route>
            {router.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  route.path === "/" ? (
                    <Login />
                  ) : route.path === "/HelpandSupport" ? (
                    <HeplandSupport />
                  ) : route.path === "*" ? (
                    <NotFound />
                  ) : (
                    <Layout hideSidebar={route.hideSidebar}>
                      <route.component />
                    </Layout>
                  )
                 
                }
              />
            ))}
          </Routes>
        </Suspense>
        {/* </HashRouter> */}
      </Router>
    </MyContextProvider>
  );
}

// const Layout: React.FC<LayoutProps> = ({ children }) => (
// const Layout: React.FC<
//   LayoutProps & { hideSidebar?: boolean; classNameHide?: boolean }
// > = ({ children, hideSidebar, classNameHide }) => (
//   <React.Fragment>
//     <Header />
//     <Box sx={{ display: 'flex' }}>
//       <Box sx={{ width: '150px', paddingLeft: 0 }}>
//         <CommonSidebar />
//       </Box>
//       <Box sx={{ flexGrow: 1, mt: 5 }}>
//         {!hideSidebar ? (
//           <Box className={classNameHide ? '' : 'home_center'}>
//             <Grid
//               container
//               spacing={2}
//               alignItems="baseline"
//               justifyContent="center"
//               sx={{ padding: "20px" }}
//               className="page-grid"
//             >
//               <Grid item xs={5} sm={3.3} lg={2.1}>
//                 {/* <Sidebar /> */}
//               </Grid>
//               <Grid item xs={7} sm={8.7} lg={9.9}>
//                 {children}
//               </Grid>
//             </Grid>
//           </Box>
//         ) : (
//           <React.Fragment>{children}</React.Fragment>
//         )}
//       </Box>
//     </Box>
//     <Footer />
//   </React.Fragment>
// );

const Layout: React.FC<
  LayoutProps & { hideSidebar?: boolean; classNameHide?: boolean }
> = ({ children, hideSidebar, classNameHide }) => {
  const location = useLocation();
  const pathname = location.pathname;

  // Check if the current pathname is either '/directMessage' or '/circleMessage'
  const isSpecialMessage =
    pathname === "/directMessage" || pathname === "/circleMessage";

  return (
    <React.Fragment>
      {!isSpecialMessage && <Header />}
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            paddingLeft: 0,
            backgroundColor: "#FFFFFF",
            borderRight: "1px solid #E5E5E5",
            width: "64px",
          }}
        >
          <CommonSidebar />
        </Box>

        <Box sx={{ flexGrow: 1, mt: 5 }}>
          {isSpecialMessage && <ChatHeader />}

          {!hideSidebar ? (
            <Box className={classNameHide ? "" : "home_center"}>
              <Grid
                container
                spacing={2}
                alignItems="baseline"
                justifyContent="center"
                sx={{ padding: "20px" }}
                className="page-grid"
              >
                {!isSpecialMessage && (
                  <Grid item xs={5} sm={3.3} lg={2.1}>
                    <Sidebar />
                  </Grid>
                )}
                <Grid
                  className="home_center"
                  // sx={{paddingTop:'1px', backgroundColor:'red'}}
                  item
                  xs={isSpecialMessage ? 12 : 7}
                  sm={isSpecialMessage ? 12 : 8.7}
                  lg={isSpecialMessage ? 12 : 9.9}
                >
                  {children}
                </Grid>
              </Grid>
            </Box>
          ) : (
            <React.Fragment>{children}</React.Fragment>
          )}
        </Box>
      </Box>
      {!isSpecialMessage && <Footer />}
    </React.Fragment>
  );
};

export default App;
