import React, { useState } from 'react';
import { subPath } from '../../../../Constants/Global';
import { Document, Page, pdfjs } from 'react-pdf';
import { Grid } from '@mui/material';


// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.mjs',
//   import.meta.url,
// ).toString();
interface ImagePreviewProps {
  image: File;
  onImageSelected: () => void;

}
const PreviewAttachment: React.FC<any> = ({ image, onImageSelected }) => {
  const imageUrl = image.previewUrl;
  console.log("image==>", image);
  const renderFilePreview = (file: any) => {
    console.log("file.previewUrl-------->",file.previewUrl)
    switch (file.file.type) {
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return <img src={file.previewUrl} alt="preview" className="thumbnail" />;
      case 'application/pdf':
      case 'text/plain':
      case 'application/doc':
      case 'application/xlsx':
        
        return (
          <div >

            {/* <img
              src={`..${subPath}/assets/images/images/File_icon.png`}
              alt="file_icon"
              style={{ width: "30px", height: "30px" }}
            /> */}
            <iframe src = {file.previewUrl} width ="300px" height= "400px"/>

            

          </div>
        );
        case 'video/mp4':
      case 'video/webm':
      case 'video/ogg':
        return (
          <video width="320" height="240" controls>
            <source src={file.previewUrl} type={file.file.type} />
            Your browser does not support the video tag.
          </video>
        ) ;
         case 'audio/mpeg':
        case 'audio/ogg':
        case 'audio/wav':
          return (
            <audio controls>
              <source src={file.previewUrl} type={file.file.type} />
              Your browser does not support the audio element.
            </audio>
          );

      default:
        return <img src={file.previewUrl} alt="preview" className="thumbnail" />;
        
    }
  };

  return (
    <Grid item className="image-preview" sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
      {renderFilePreview(image)}
      {/* {imageUrl && <PdfViewer imageURL={imageUrl} />} */}
    </Grid>
  );
};

export default PreviewAttachment;
