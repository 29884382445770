import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { decodeToken } from "../../../Constants/Global";
import ChatList from "./ChatMessage/ChatList";
import { Grid } from "@mui/material";
import { getUserList } from "../../../Redux/chatList/action";
import { useAppDispatch, useAppSelector } from "../../../Constants/hooks";
const emailUser: string | null = decodeToken()?.Email ?? null;
const nameUser: string | null = decodeToken()?.Name ?? null;
const directMessage: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const data = {
      userName: nameUser,
      emailId: emailUser,
      mobileNo:"",
      designation: 'Employee',
      department: 'IT',
      deviceToken:'',
      mobileNo :""

    };
    const signal = new AbortController();
    dispatch(getUserList(data, signal));
  }, [dispatch]);

  const userData = useAppSelector((state: any) => state.chatListData.userInfo);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <ChatList userList={userData} />
      </Grid>
    </Box>
  );
};

export default directMessage;