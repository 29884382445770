import React, { useState } from "react";
import { Document, Page, pdfjs } from 'react-pdf';
import { subPath } from "../../../../Constants/Global";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Button, Grid } from "@mui/material";
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

interface ImageFile {
  file: File;
  previewUrl: string;
}

interface ThumbnailCarouselProps {
  images: ImageFile[];
  onThumbnailClick: (image) => void;
}


const ThumbnailCarousel: React.FC<ThumbnailCarouselProps> = ({
  images,
  onThumbnailClick,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const onPrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      onThumbnailClick(images[currentIndex - 1]);
    }
  };

  const onNextClick = () => {
    if (currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
      onThumbnailClick(images[currentIndex + 1]);
    }
  };

  const handleThumbnailClick = (index: number) => {
    setCurrentIndex(index);
    onThumbnailClick(images[index]);
  };
  
  const renderFilePreview = (file: ImageFile) => {
    switch (file.file.type) {
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return <img src={file.previewUrl} alt="preview" className="thumbnail" />;
      case 'application/pdf':
      case 'text/plain':
      case 'application/doc':
      case 'application/xlsx':
        return (
          <Grid container sx={{ justifyContent:'center'}}>
            <Grid item >
            <img
              src={`..${subPath}/assets/images/images/File_icon.png`}
              alt="file_icon"
              style={{ width: "30px", height: "30px" }}
            />
            </Grid >
            <Grid xs={3} item sx={{display:'flex', alignItems:'center'}} className="file_lable_doc">
            {file.file.name}
            </Grid>
          </Grid>
        );
      case 'video/mp4':
      case 'video/webm':
      case 'video/ogg':
        return (
          <video width="100" height="100" controls>
            <source src={file.previewUrl} type={file.file.type} />
            Your browser does not support the video tag.
          </video>
        );
        case 'audio/mpeg':
        case 'audio/ogg':
        case 'audio/wav':
          return (
            <audio controls>
              <source src={file.previewUrl} type={file.file.type} />
              Your browser does not support the audio element.
            </audio>
          );
      default:
        return <img src={file.previewUrl} alt="preview" className="thumbnail" />;
        case 'AUDIO':
      // Handle audio view
      break;
    
    }
  };

  return (
    <div>
      <div className="thumbnail-carousel">
        <Button
          className="prev-icon"
          onClick={onPrevClick}
          disabled={currentIndex === 0}
        >
          <img
            src={`..${subPath}/assets/images/images/Back_Button.png`}
            alt="back Arrow"
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
          />
        </Button>
        {images.map((file, index) => (
          <Grid
            item
            key={index}
            className={`thumbnail-container ${
              index === currentIndex ? "" : ""
            }`}
            onClick={() => handleThumbnailClick(index)}
          >
            {renderFilePreview(file)}
            <Grid item sx={{ display: "flex", justifyContent: "center" }}>
              <img
                src={` ${
                  index === currentIndex
                    ? `..${subPath}/assets/images/images/Line_image.png`
                    : ` `
                }`}
              />
            </Grid>
          </Grid>
        ))}
        <Button
          className="next-icon"
          onClick={onNextClick}
          disabled={currentIndex === images.length - 1}
        >
          <img
            src={`..${subPath}/assets/images/images/next_Button.png`}
            alt="Next Arrow"
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
          />
        </Button>
      </div>

      {images[currentIndex] && (
        <Grid item className="modal">
          <span className="close" onClick={() => setCurrentIndex(-1)}>
            &times;
          </span>
          <img
            className="modal-content"
            src={images[currentIndex].previewUrl}
            alt="Selected"
          />
        </Grid>
      )}
    </div>
  );
};

export default ThumbnailCarousel;
