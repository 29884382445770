import React, { useState, useEffect, useRef } from "react";
import "../ChatMessage/ChatView.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { MessageBox } from "react-chat-elements";
import "react-chat-elements/dist/main.css";

import {
  subPath,
  ENC_DEC_KEY,
  decodeToken,
} from "../../../../Constants/Global";
import { Grid, Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../Constants/hooks";
import CryptoJS from "crypto-js";
import { styled } from "@mui/material/styles";
import {
  getChatView,
  deletePrivateChat,
  getPinnedMessage,
  savePinnedMessage,
  deleteChatMessage,
  updateChatMessage,
  deletePinnedMessage,
} from "../../../../Redux/Chatview/action";
import { getChatList } from "../../../../Redux/chatList/action";
interface ChatReplyProps {
  chatDetails: any;
  userData:any;
  index: number;
  message: any;
  isProfileShow: boolean;
  isForwardMessage: boolean;
  isReplying: boolean;
  handelReplyClose: () => void;
}
interface ChatMessage {
  senderName: string;
  recipientName?: string;
  message: string;
  status: string;
  action: string;
  senderId: string;
  recipientId: string;
  groupId: string;
  groupName: string;
  type: string;
  senderProfilePicture: string;
  replyTo: any;
  replySenderName: string;
  replySenderId: string;
  deviceToken: string;
  groupProfilePicture: string;
  department: string;
  designation: string;
  colorCode: string;
}
const STRINGS = {
  new: "new",
  text: "text",
};
interface DeletePinnedMessage {
  pinnedMessageId: string;
}
// tooltip

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,

    boxShadow: "0px 0px 8px 0px #0000001F",
    padding: "4px, 6px, 4px, 6px",
    fontSize: 14,
    color: "#000000",
    fontWeight: 400,
    fontFamily: "Montserrat",
  },
}));

const userIdSend: string | null = decodeToken()?.id ?? null;
const ChatReply: React.FC<ChatReplyProps> = ({
  chatDetails,
  userData,
  index,
  isProfileShow,
  isForwardMessage,
  message,
  isReplying,
  handelReplyClose,
}) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [chatHover, setChatHover] = useState<null | HTMLElement>(null);
  const [empty, setEmpty] = useState<null | boolean>(false);
  const [anchorElPin, setAnchorElPin] = React.useState<null | HTMLElement>(
    null
  );
  //update seenAt
  const [abortController, setAbortController] = useState(new AbortController());
  const dispatch = useAppDispatch();
  const newAbortController = new AbortController();
  const chatMessage = useAppSelector(
    (state: any) => state?.chatGetMessage?.chatView
  );
  const pinnedChatMessage = useAppSelector(
    (state: any) => state?.chatGetMessage?.pinnedMessage
  );
  const userProfilePicture =
    ".." + subPath + "/assets/images/images/PROFILE_PHOTO.png";
  const pinnedMessageData = {
    userId: chatDetails?.chatDetails?.senderId,
    recipientId: chatDetails?.chatDetails?.recipientId,
    groupId: "",
  };
  const userDetails = {
    senderId: chatDetails?.chatDetails?.senderId,
    recipientId: chatDetails?.chatDetails?.recipientId,
    pageNo: 1, // Add this property
    rowPerPage: 10, // Add this property
  };
  console.log(pinnedChatMessage, "pinnedChatMessage");
  console.log("messageeeee...",message);
  console.log("chatDetails....",chatDetails);
  
  useEffect(() => {
    setAbortController(newAbortController);

    // chatDetails?.chatDetails
    //   ? dispatch(getChatView(userDetails, newAbortController))
    //   : "";
    // chatDetails.chatDetails
    //   ? dispatch(getPinnedMessage(pinnedMessageData, newAbortController))
    //   : "";
    setEmpty(chatDetails?.chatDetails?.empty);
    initWebSocket();
    changeChatView();
    // Cleanup function for socket closure
    // return () => {
    //   if (socketRef.current) {
    //     socketRef.current.close();
    //   }
    // };
  }, [dispatch, chatDetails]);

  //Delete Private Chat
  const [chatId, setChatId] = useState(null);
  const handleDeletePrivateChat = () => {
    const data = {
      chatId: chatId,
    };
    dispatch(deletePrivateChat(data));
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const open = Boolean(chatHover);
  const id = open ? "simple-popover" : undefined;
  const openPin = Boolean(anchorElPin);

  //

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  // const encrypt = (text: string) => {
  //   return CryptoJS.AES.encrypt(text, ENC_DEC_KEY).toString();
  // };
  function encrypt(value: string) {
    const key = CryptoJS.enc.Utf8.parse(ENC_DEC_KEY);
    const encryptedData = CryptoJS.AES.encrypt(value, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encryptedData.toString();
  }

  function decrypt(value: string | CryptoJS.lib.CipherParams): string {
    if (value && value !== "") {
      try {
        const key = CryptoJS.enc.Utf8.parse(ENC_DEC_KEY);

        // Decrypting the value
        const decrypted = CryptoJS.AES.decrypt(value, key, {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        });

        // Converting decrypted value to UTF-8 string
        const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);

        // Logging decrypted value for debugging
        console.log("Decrypted value:", decryptedString);

        // Return decrypted string
        return decryptedString;
      } catch (error) {
        console.error("Error during decryption:", error);
        return "";
      }
    }

    return "";
  }
  const [hoveredMessageId, setHoveredMessageId] = useState<null | number>(null);
  const [hoveredMessageReply, setHoveredMessageReply] = useState<null | number>(
    null
  );

  const [isPinShow, setIsPinShow] = useState(
    pinnedChatMessage.length != 0 ? true : false
  );
  const handlePinShow = (message: any) => {
    const data = {
      userId: chatDetails?.chatDetails?.senderId,
      recipientId: chatDetails?.chatDetails?.recipientId,
      groupId: message.groupId,
      messageId: message.messageId ? message.messageId : generateUniqueId(),
      message: message,
    };
    dispatch(savePinnedMessage(data))
      .then(() => {
        dispatch(getPinnedMessage(pinnedMessageData, newAbortController));
        setIsPinShow(true);
      })
      .catch((error) => {
        setIsPinShow(false);
        console.error("Failed to save pinned message:", error);
      });
  };

  // WebSocket
  const socketRef = useRef<WebSocket | null>(null);
  const [connectionState, setConnectionState] = useState<
    "CONNECTING" | "OPEN" | "CLOSED"
  >("CONNECTING");
  const [messages, setMessages] = useState<ChatMessage[]>([]);  
  const [refreshKey, setRefreshKey] = useState(0);
  const [messageInput, setMessageInput] = useState("");
  const [isForwardMessageShow, setIsForwardMessageShow] = useState(false);

  const initWebSocket = () => {
    const newSocket = new WebSocket(
      `wss://trove-dev-gateway.citpl.info/chat-module/ws/` +
      chatDetails?.chatDetails?.senderId +
      `/goldfish_arm64`
    );
    socketRef.current = newSocket;

    newSocket.onopen = () => {
      console.log("WebSocket connection established.");
      setConnectionState("OPEN");
    };

    newSocket.onclose = () => {
      console.log("WebSocket connection closed.");
      setConnectionState("CLOSED");
      // Reopen WebSocket after a delay (optional)
      // setTimeout(() => {
      initWebSocket();
      // }, 1000); // Try reconnecting after 5 seconds
    };

    newSocket.onmessage = (event) => {
      const receivedMessage = JSON.parse(event.data);
      setMessages((prevMessages) => [...prevMessages, receivedMessage]);
    };
  };
  const chatPageNumber: any = useRef(1);
  const [msgchat, setMsgChat] = useState({
    privateChatMessages: [],
    totalNumberOfPages: 0,
  });
  const fetchChatList = () => {
    const data = {
      userId: userData?.id,
      searchValue: "",
      pageNo: 1,
      rowPerPage: 10,
    };
    const signal = new AbortController();
    dispatch(getChatList(data, signal.signal));
  };
  useEffect(() => {
    if (refreshKey>0) {
      const data = {
        userId: userData?.id,
        searchValue: "",
        pageNo: 1,
        rowPerPage: 10,
      };
      const signal = new AbortController();
      dispatch(getChatList(data, signal.signal));
    }
  }, [refreshKey, dispatch]);
  const handleFetchPrevChat = (updateCall: any) => {
    chatPageNumber.current = updateCall ? 0 : chatPageNumber.current + 1;
    dispatch(
      getChatView(
        {
          senderId: chatDetails?.chatDetails?.senderId,
          recipientId: chatDetails?.chatDetails?.recipientId,
          pageNo: chatPageNumber.current,
          rowPerPage: 10,
        },
        newAbortController
      )
    );
  };
  const clearInput = () => {
    setMessageInput("");
  };
  const [conChange,setConchange]:any = useState(true);
  const sendMessage = (message:any) => {
    console.log("reply..............",message);
    if (
      socketRef.current &&
      connectionState === "OPEN" &&
      messageInput.trim() !== ""
    ) {
      if(!chatDetails?.chatDetails){
        const messageData: ChatMessage = {
          action: "reply",
          groupId: chatDetails?.groupId,
          groupName: chatDetails?.groupName,
          groupProfilePicture: "",
          message: JSON.stringify({
            id: generateUniqueId(),
            content: encrypt(messageInput),
            caption: "",
            type: STRINGS.text,
            sendAt: new Date(),
            updatedAt: new Date(),
            deliveredAt: "",
            seenAt: "",
          }),
          replySenderId:  message?.senderId,
          replySenderName:  message?.senderName,
          replyTo:  JSON.stringify({
            id: message?.message?.id,
            type: "TEXT",
            content: encrypt(message?.message?.content),
            caption: "",
            size: 0,
            name: null,
            seenAt: null,
            sendAt: new Date(),
            updatedAt: new Date(),
            createdAt: new Date()
        }),
        recipientId: message?.recipientId,
        recipientName: message?.recipientName,
        senderId: userData?.id,
        senderName: userData?.fullname,
        senderProfilePicture: "",
        department: "",
        designation: "",
        colorCode: "",
        type: "TEXT",
        deviceToken: "",
        status: "sent",
        };
        console.log(".........messageData",messageData);

        socketRef.current.send(JSON.stringify(messageData));
        setRefreshKey((prevKey) => prevKey + 1);
        setMessages((prevMessages) => [...prevMessages, messageData]);
      }
     else{
        console.log("chatDetails?.chatDetails?.recipientId",chatDetails?.chatDetails?.recipientId);
        console.log("message?.senderId",message);
        
        const messageData: ChatMessage = {
          action: "reply",
          groupId: "",
          groupName: "",
          groupProfilePicture: "",
          message: JSON.stringify({
            id: generateUniqueId(),
            content: encrypt(messageInput),
            caption: "",
            type: STRINGS.text,
            sendAt: new Date(),
            updatedAt: new Date(),
            deliveredAt: "",
            seenAt: "",
          }),
          recipientId: message?.recipientId,
          recipientName: message?.recipientName,
          replySenderId:  message?.senderId,
          replySenderName:  message?.senderName,
          replyTo:  JSON.stringify({
            id: message?.message?.id,
            type: "TEXT",
            content: encrypt(message?.message?.content),
            caption: "",
            size: 0,
            name: null,
            seenAt: null,
            sendAt: new Date(),
            updatedAt: new Date(),
            createdAt: new Date()
        }),
        senderId: message?.senderId,
        senderName: message?.senderName,
          senderProfilePicture: message?.replySenderProfilePicture,
          department: chatDetails?.chatDetails?.recipientDepartment,
          designation: chatDetails?.chatDetails?.recipientDesignation,
          colorCode:"",
          type: "TEXT",
          deviceToken: "",
          status: "sent",
        };
        console.log(".........messageData",messageData);

        socketRef.current.send(JSON.stringify(messageData));
        const value = msgchat.privateChatMessages.map((obj: any) =>
          obj.message.id  ? message : obj
        );
        setTimeout(() => {
          fetchChatList();
          handleFetchPrevChat(true);
        },1000);
        setConchange(false);
        clearInput();
      }
        
      }
      

     else {
      initWebSocket();
    }
    setIsEditing(true);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>,message:any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage(message);
      handelReplyClose();
    }
  };

  const generateUniqueId = () => {
    return Math.random().toString(36).substr(2, 9);
  };

  const changeChatView = () => {
    if (empty == true) {
      setMessages([]);
      clearInput();
    }
  };
useEffect(()=>{
  console.log(".......messages",messages);
  if(message?.replyTo !== null){
    setEditedContentReplay(decrypt(message?.replyTo));
  }
},[messages])
  const handleForwardMessageShow = () => {
    setIsForwardMessageShow(!isForwardMessageShow);
  };
  // pin

  const handleDeleteMessage = (message: any) => {
    console.log("hello");
    dispatch(deleteChatMessage(message))
      .then(() => {
        dispatch(getChatView(userDetails, newAbortController));
        setMessages((prevMessages) => [
          ...prevMessages,
          chatMessage?.privateChatMessages,
        ]);
      })
      .catch((error) => {
        console.error("Failed to delete Message:", error);
      });
  };

  const unPinnedMessage = (id: any) => {
    const data: DeletePinnedMessage = {
      pinnedMessageId: id,
    };
    dispatch(deletePinnedMessage(data))
      .then(() => {
        dispatch(getPinnedMessage(pinnedMessageData, newAbortController));
        setIsPinShow(false);
      })
      .catch((error) => {
        setIsPinShow(true);
        console.error("Failed to un pinned message:", error);
      });
  };

  // edit

  const [editingMessageReplay, setEditingMessageReplay] = useState(null);
  const [editedContentReplay, setEditedContentReplay] = useState("");
  const inputRefReply = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // Focus the input element when it is in the editing state
    if (inputRefReply.current) {
      inputRefReply.current.focus();
    }
  }, [editingMessageReplay]);
  const sendMessageEnter = (message) => {
    console.log(`Send Message: ${editedContentReplay}`);
    const messageData = {
      action: "new",
      groupId: "",
      groupName: "",
      groupProfilePicture: "",
      message: {
        id:  message.message.id,
        content: encrypt(editedContentReplay),
        caption: "",
        type: "TEXT",
        sendAt: new Date(),
        updatedAt: new Date(),
        deliveredAt: "",
        seenAt: "",
      },
      recipientId: chatDetails?.chatDetails?.recipientId,
      recipientName: chatDetails?.chatDetails?.recipientName,
      replySenderId: "",
      replySenderName: "",
      replyTo: "",
      senderId: chatDetails?.chatDetails?.senderId,
      senderName: chatDetails?.chatDetails?.senderName,
      senderProfilePicture: "",
      department: "",
      designation: "",
      colorCode: "",
      type: "TEXT",
      deviceToken: "",
      status: "sent",
    };
    console.log(messageData);
    updateMessage(messageData);
    setRefreshKey((prevKey) => prevKey + 1);
  };
  const updateMessage = (message: any) => {
    dispatch(updateChatMessage(message));
  };
  const handleEditClickReply = (messageId: any, currentContent: any) => {
    setIsEditing(true);

    setEditingMessageReplay(messageId);
    setEditedContentReplay(currentContent);
  };
  const handleContentChangeReply = (event: any) => {
    setEditedContentReplay(event.target.value);
  };

  const handleKeyPressReply = (event: any,message:any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSaveClick(editingMessageReplay);
      sendMessageEnter(message);
    }
  };
  const [isEditing, setIsEditing] = useState(false);
  const handleEditStart = () => {
    setIsEditing(true);
    // setEditedContentReplay(decrypt(JSON.parse(message.message).content));
  };
  const handleSaveClick = (messageId: any) => {
    console.log(
      `Message ID: ${messageId}, New Content: ${editedContentReplay}`
    );
    // Save the edited content here (e.g., update the message in your state or send it to a server)
    setEditingMessageReplay(null);
  };

  return (
    <>
      {/* {messages.map((message, index) => { */}
      {/* })} */}
      {conChange?
      <Grid item xs={12} lg={12}>
      <Box
        key={index}
        
        className={
          isProfileShow || isForwardMessage  === true
            ? "Profile_show_cht" 
            : isReplying === true ? "message_reply_cht"
            :"Profile_hide_cht" 
        }
        sx={{
          padding: 0,
          margin: "auto",
          marginRight: "0px",
          paddingTop: "20px",
          display: "flex",
        }}
      >
        <Avatar
          alt="Profile"
          style={{
            borderRadius: "20%",
            width: "35px",
            height: "35px",
          }}
          src={message.senderProfilePicture}
        />
        <Box
          onMouseEnter={() => setHoveredMessageReply(index)}
          onMouseLeave={() => setHoveredMessageReply(null)}
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            paddingLeft: "10px",
          }}
        >
          <Typography
            onMouseEnter={() => setHoveredMessageReply(index)}
            onMouseLeave={() => setHoveredMessageReply(null)}
            aria-owns={
              hoveredMessageId === index ? "mouse-over-popover" : undefined
            }
            aria-haspopup="true"
            className="hide_Time"
          >
            <MessageBox
              position={"left"}
              type={"text"}
              text={
                !isEditing ? (
                  <> <input
                  ref={inputRefReply}
                  style={{
                    cursor: "pointer",
                    border: "none",
                    width: "100%",
                    outline: "none",
                  }}
                  type="text"
                  value={messageInput}
                  onKeyPress={(event) =>
                    handleKeyDown(event, message)
                          }
                  onChange={(e) => setMessageInput(e.target.value)}
                /></>
                ) : (
                  decrypt(message?.message?.content)
                )
              }
              // text={
              //   isEditing ? (
              //     <input
              //       ref={inputRefReply}
              //       style={{
              //         cursor: "pointer",
              //         border: "none",
              //         width: "100%",
              //         outline: "none",
              //       }}
              //       type="text"
              //       value={editedContentReplay}
              //       onKeyPress={(event) =>
              //         handleKeyPressReply(event, message)
              //       }
              //       onChange={handleContentChangeReply}
              //     />
              //   ) : (
              //     <> <input
              //     ref={inputRefReply}
              //     style={{
              //       cursor: "pointer",
              //       border: "none",
              //       width: "100%",
              //       outline: "none",
              //     }}
              //     type="text"
              //     value={messageInput}
              //     onKeyPress={handleKeyDown}
              //     onChange={(e) => setMessageInput(e.target.value)}
              //   /></>
              //   )
              // }
              title={
                message.senderName.charAt(0).toUpperCase() +
                message.senderName.slice(1)
              }
              date={message?.messag && new Date(JSON.parse(message?.message)?.sendAt)}
              id={index.toString()}
              className="chatbox_pad_reply"
              focus={true}
              titleColor={"#000000"}
              replyButton={false}
              removeButton={false}
              forwarded={false}
              status={"sent"}
              notch={true}
              retracted={false}
              reply={{
                title:
                  message?.senderName?.charAt(0).toUpperCase() +
                  message?.senderName?.slice(1),

                titleColor: "#000000",
                message: decrypt(message?.message?.content),
                photoURL: `..${subPath}/assets/images/images/user_profile_img.png`,
              }}
            />
          </Typography>
          {hoveredMessageReply === index && (
            <Grid
              onMouseEnter={() => setHoveredMessageReply(index)}
              item
              sx={{
                position: "absolute",
                bottom: "-30px",
                display: "flex",
                gap: "10px",
                backgroundColor: "#fff",
                right: "0px",
                padding: "5px",
                borderRadius: "8px",
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                zIndex: 1,
              }}
            >
              <LightTooltip title="Edit">
                <img
                  src={`..${subPath}/assets/images/images/edit_chat.png`}
                  alt="edit chat"
                  width="25"
                  height="25"
                  onClick={() =>
                    handleEditClickReply(
                      index.toString(),

                      editedContentReplay
                    )
                  }
                />
              </LightTooltip>

              <LightTooltip title="Reply">
                <img
                  src={`..${subPath}/assets/images/images/reply_chat.png`}
                  alt="reply chat"
                  width="25"
                  height="25"
                />
              </LightTooltip>
              <LightTooltip title="Forward">
                <img
                  onClick={handleForwardMessageShow}
                  src={`..${subPath}/assets/images/images/forward_chat.png`}
                  alt="forward"
                  width="25"
                  height="25"
                />
              </LightTooltip>
              <LightTooltip title="To-do">
                <img
                  src={`..${subPath}/assets/images/images/todo.png`}
                  alt="todo"
                  width="25"
                  height="25"
                />
              </LightTooltip>
              <LightTooltip title="Pin">
                <img
                  src={`..${subPath}/assets/images/images/pinned_chat.png`}
                  alt="pinned chat"
                  width="25"
                  height="25"
                  onClick={() => handlePinShow(message)}
                />
              </LightTooltip>
              <LightTooltip title="Delete">
                <img
                  style={{ paddingTop: "4px" }}
                  src={`..${subPath}/assets/images/images/trash.png`}
                  alt="trash"
                  width="18"
                  height="18"
                  onClick={() => handleDeleteMessage(message)}
                />
              </LightTooltip>
            </Grid>
          )}
        </Box>
      </Box>
    </Grid>:null}
      
    </>
  );
};

export default ChatReply;
