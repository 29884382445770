import React from 'react';
import { Modal, Button } from '@mui/material';

const ConfirmationModal = ({ open, onClose, onConfirm }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div style={{ padding: '30px', backgroundColor: 'white', margin: '10% auto', maxWidth: '300px', textAlign: 'center' }}>
        <h5>Are you sure you want to delete this chat?</h5>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          Yes
        </Button>
        <Button variant="outlined" color="secondary" onClick={onClose} style={{ marginLeft: '10px' }}>
          No
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;