import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  InputBase,
  Grid,
  Button,
 
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { getChatList, isMediaShow } from "../../../../Redux/chatList/action";
import { getChatSeen, getChatView } from "../../../../Redux/Chatview/action";
import { useAppDispatch, useAppSelector } from "../../../../Constants/hooks";
import {
  subPath,
  ENC_DEC_KEY,
  decodeToken,
} from "../../../../Constants/Global";
import CryptoJS from "crypto-js";
import _ from "lodash";
import ChatView from "../ChatMessage/ChatView";

import { RootState } from "../../../../store";
import ChatVideoView from "../ChatViewCard/MediaView";
import ThumbnailCarousel from "./ThumbnailCarousel";
import PreviewAttachment from "./PreviewAttachment";


import ChatMediaView from "../ChatViewCard/MediaView";
import Badge, { BadgeProps } from "@mui/material/Badge";

// Define an interface for your chat data

interface LastMessage {
  content: string;
  createdAt: string;
  read: boolean;
  senderId: string | null;
  senderName: string | null;
  senderProfilePicture: string | null;
  type: any;
  updatedAt: string;
  unreadMessageCount: number;
}

interface Chat {
  
  colorCode: string;
  chatId: string;
  userName: string;
  userProfilePicture: string | null;
  lastMessage: LastMessage;
  mutedChat: boolean;
  online: boolean;
  pinnedChat: boolean;
}
interface ChatData {
  online: boolean;
  recipientDepartment: string;
  recipientDesignation: string;
  recipientEmailId: string;
  recipientId: string;
  recipientName: string;
  recipientProfilePicture: string;
  senderId: String;
  senderName: String;
  empty: boolean;
}
const userId: string | null = decodeToken()?.id ?? null;

// truncat the word set max word to show, other words make three dots
const truncateContent = (content: string, maxChars: number) => {
  if (content.length > maxChars) {
    return content.slice(0, maxChars) + "...";
  }
  return content;
};

const getMessageContent = (userName:any,type: string, content: string) => {
  console.log("...........userName",userName);
  console.log("...........type",type);
  console.log("...........content",content);
  switch (type) {
    case "TEXT":
      return truncateContent(content, 25);
    case "IMAGE":
      return "Image";
    case "VIDEO":
      return "Video";
    case "AUDIO":
      return "Audio";
    case "DOCUMENT":
      return "Document";
    default:
      return "";
  }
};

//Decrypt encrypted text
export function decrypt(value: string | CryptoJS.lib.CipherParams): string {
  if (value && value !== "") {
    try {
      const key = CryptoJS.enc.Utf8.parse(ENC_DEC_KEY);

      // Decrypting the value
      const decrypted = CryptoJS.AES.decrypt(value, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });

      // Converting decrypted value to UTF-8 string
      const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);

      // Logging decrypted value for debugging
      console.log("Decrypted value:", decryptedString);

      // Return decrypted string
      return decryptedString;
    } catch (error) {
      console.error("Error during decryption:", error);
      return "";
    }
  }

  return "";
}
const chatList: React.FC<any> = (userList:any) => {
  const dispatch = useAppDispatch();
  const newAbortController = new AbortController();

  const [chat, setChat] = useState<any>();
  const[chatData,setChatData] = useState<any>();

  const [activeChat, setActiveChat] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const fetchChatList = useCallback(
    (searchValue = '') => {
      if (!userList || !userList.userList || !userList.userList.id) {
        return;
      }
      const data = {
        userId: userList.userList.id,
        searchValue: searchValue,
        pageNo: 1,
        rowPerPage: 10,
      };
      const signal = new AbortController();
      dispatch(getChatList(data, signal.signal));
    },
    [dispatch, userList]
  );

  const debouncedSearch = useCallback(
    _.debounce((value) => {
      fetchChatList(value);
    }, 300),
    [fetchChatList]
  );

  useEffect(() => {
    if (userList && userList.userList) {
      fetchChatList();
    }
  }, [fetchChatList, userList]);

  useEffect(() => {
    if (searchValue) {
      debouncedSearch(searchValue);
    } else {
      debouncedSearch('');
    }
  }, [searchValue, debouncedSearch]);

  const chatListData = useAppSelector(
    (state: any) => state?.chatListData?.chatList
  );

  useEffect(() => {
    if (chatListData && chatListData.chatMembersList) {
      const uniqueChatMembers = chatListData.chatMembersList.reduce((acc: any[], current: any) => {
        const x = acc.find(item => item.userName === current.userName);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      
      const sortedChatData = [...uniqueChatMembers].sort(
        (a: any, b: any) => new Date(b.lastMessage.updatedAt).getTime() - new Date(a.lastMessage.updatedAt).getTime()
      );
      setChatData(sortedChatData);
    }
  }, [chatListData]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const searchInputRef:any = useRef(null);
  const handleChatClick = (chat: any) => {
    
    const userDetails = {
      senderId: userList.userList.id,
      recipientId: chat.userId,
    };
    const signal = new AbortController();

    dispatch(getChatSeen(userDetails, signal));
    const data: any = {
      online: chat.online,
     
      recipientDepartment: chat.userDepartment,
      recipientDesignation: chat.userDesignation,
      recipientEmailId: chat.userEmailId,
      recipientId: chat.userId,
      recipientName: chat.userName,
      recipientProfilePicture: chat.userProfilePicture,
      senderId: userList.userList.id,
      senderName: userList.userList.fullname,
      empty: true,
      chatId: chat.chatId
    };

    userList.userList.id
      ? setTimeout(()=>{
        dispatch(
          getChatView(
            {
              senderId: userList.userList.id,
              recipientId:  chat.userId,
              pageNo: 0, // Add this property
              rowPerPage: 10, // Add this property
            },
            newAbortController
          )
        ),fetchChatList();
      })
      : "";
    setChat(data);
    setSearchValue("");
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
    }
    setActiveChat(chat);

  };



  //image


  const sendButtonStyle = {
    // Define your send button styles here
  };

const isMediaView = useAppSelector((state: any) => state?.chatListData?.isMediaView);
console.log(isMediaView,"isMediaView");



  return (
    <>
      {!isMediaView ? (
        <Grid
          container
          sx={{
            position: "fixed",
            width: { xs: "88.6%", sm: "93%", md: "95%", lg: "96%" },
          }}
        >
          <Grid
            item
            xs={5}
            sm={2.85}
            lg={2.85}
            sx={{
              backgroundColor: "white",
              width: "102px",
              borderRight: "1px solid #E5E5E5",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                height: "100vh",
                backgroundColor: "#ffffff",
              }}
            >
              <Grid
                item
                sx={{
                  height: "100px",
                  backgroundColor: "#ffffff",
                  position: "sticky",
                  top: "60px",
                  zIndex: 1,
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    background:
                      "linear-gradient(87.09deg, #35BA79 4.84%, #119BFF 95.16%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontWeight: "700",
                    textAlign: "left",
                    display: "inline-block",
                    fontSize: "16px",
                    padding: "10px",
                    paddingTop: "25px",
                    fontFamily: "Montserrat",
                  }}
                >
                  Direct Message
                </Typography>
                <Box
                  className=" "
                  style={{
                    cursor: "text",
                    padding: "10px",
                    paddingTop: "4px",
                  }}
                >
                  <div
                    className="search_header_list d-flex"
                    style={{ alignItems: "center" }}
                  >
                    <img
                      //   src="../beta/assets/images/search.svg"
                      src={`..${subPath}/assets/images/images/searchIocn_chart.png`}
                      alt="Search"
                      style={{ width: "18px", height: "18px" }}
                    />
                    <InputBase
                      placeholder="Search members"
                      className="ml-15 input_search"
                      sx={{
                        background: "#fffff",
                        boxSizing: "border-box",
                        height: "35px",
                        borderRadius: "5px",
                      }}
                      id="searchText"
                      autoComplete="off"
                      value={searchValue}
                      ref={searchInputRef}
                      onChange={handleSearchChange}
                    />
                  </div>
                </Box>
              </Grid>
              <List
                className="chatList_list"
                sx={{
                  paddingTop: "12px ",
                  paddingBottom: "0px",

                  width: "100%",
                  height: "72%",
                  overflowY: "auto",
                }}
              >
                {Array.isArray(chatData) &&
                  chatData?.map(
                    (chat: any, index: number) => (
                      <ListItem
                        className={`chat_list_hover ${activeChat === chat ? "active_chat" : ""
                          }`}
                        alignItems="flex-start"
                        key={index}
                        sx={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingBottom: "6px",
                          paddingTop: "0px",
                          backgroundColor:
                            activeChat === chat ? "#f0f0f0" : "inherit",
                        }}
                        onClick={() => handleChatClick(chat)}
                      >
                        <ListItemAvatar
                          sx={{ width: "50px", minWidth: "50px" }}
                        >
                          {chat?.online === true ?(
                            
                         
                          <Badge
                            className="chat_badge"
                            badgeContent={
                              <> {chat?.online && <Grid
                                item
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={`..${subPath}/assets/images/images/online_img.png`}
                                  alt="online"
                                  style={{ width: "10px", height: "10px" }}
                                />
                              </Grid>}
                              </>}
                            color="primary"
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                          >
                            <Avatar
                              alt="Profile"
                              sx={{
                                borderRadius: "20%",
                                width: "35px",
                                height: "35px",
                                backgroundColor: chat?.colorCode || "#119BFF",
                              }}
                              src={chat?.userProfilePicture || undefined}
                            >
                              {!chat?.userProfilePicture &&
                                chat?.userName.charAt(0).toUpperCase()}
                            </Avatar>
                          </Badge>
                           ):(
                            <Avatar
                            alt="Profile"
                            sx={{
                              borderRadius: "20%",
                              width: "35px",
                              height: "35px",
                              backgroundColor: chat?.colorCode || "#119BFF",
                            }}
                            src={chat?.userProfilePicture || undefined}
                          >
                            {!chat?.userProfilePicture &&
                              chat?.userName.charAt(0).toUpperCase()}
                          </Avatar>
                           )}
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ marginTop: "9px", marginBottom: "0px" }}
                          primary={
                            <React.Fragment>
                              <Grid container>
                                <Grid item xs={6} md={7}>
                                  <Grid item>
                                    <Typography
                                      variant="body1"
                                      className="name_title_chat"
                                      sx={{
                                        fontWeight: chat?.sendByRecipient == false || chat?.lastMessage?.read || decrypt(chat?.lastMessage?.content) == ""
                                          ? "500"
                                          : "bold",
                                      }}
                                    >
                                      {truncateContent(
                                        chat?.userName?.charAt(0)?.toUpperCase() +
                                          chat?.userName?.slice(1),
                                        18
                                      )}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      className="name_Subtit_chat"
                                      variant="body2"
                                      sx={{
                                        display: "inline",
                                        color: "#708090",
                                      }}
                                    >
                                      {getMessageContent(
                                        chat?.userName,
                                        chat?.lastMessage?.type,
                                        decrypt(chat?.lastMessage?.content) || ""
                                      )}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid item xs={6} md={5}>
                                  <Grid item sx={{ textAlign: "end" }}>
                                    <Typography
                                      className="time_lable"
                                      variant="body2"
                                      sx={{
                                        fontWeight: chat?.sendByRecipient == false || chat?.lastMessage?.read || decrypt(chat?.lastMessage?.content) == ""
                                          ? "normal"
                                          : "bold",
                                      }}
                                    >
                                      {new Date(
                                        chat?.lastMessage?.updatedAt
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: true,
                                      })}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    sx={{
                                      display: "flex",
                                      justifyContent: "end",
                                      alignItems: "center",
                                    }}
                                  >
                                    {chat?.lastMessage?.unreadMessageCount > 0 && 
                                  <Grid item>
                                  <Typography
                                    className="Chat_message_count"
                                  // sx={{ fontSize: "10px", fontWeight: 500, fontFamily:'Montserrat' }}
                                  >
                                    {chat?.lastMessage?.unreadMessageCount}
                                  </Typography>
                                </Grid>  
                                  }
                                    
                                    <Grid
                                      item
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >

                                      {chat?.mutedChat && <img
                                        //   src="../beta/assets/images/search.svg"
                                        src={`..${subPath}/assets/images/images/notification_chat.png`}
                                        alt="Search"
                                        style={{
                                          width: "14px",
                                          height: "14px",
                                          marginLeft: "9px",
                                        }}
                                      />}
                                    </Grid>
                                    <Grid
                                      item
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "9px",
                                      }}
                                    >
                                      {chat?.pinnedChat && <img
                                        //   src="../beta/assets/images/search.svg"
                                        src={`..${subPath}/assets/images/images/pin_angle_chat.png`}
                                        alt="Search"
                                        style={{
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      />}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    )
                  )}
              </List>
            </Box>
          </Grid>

          {chat?.senderId  && (
            <Grid item xs={7} sm={9.15} lg={9.15}>
              <ChatView chatDetails={chat} chatID={chat?.chatId}  userValue={userList.userList.id}/>
            </Grid>
          )}
        </Grid >
      ) : (
        <Grid container sx={{position:'fixed'}}>
          <Grid item xs={12} sm={12}>
        
            <ChatMediaView chatDetails={chat}/>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default chatList;
