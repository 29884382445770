import React, { useState, useRef, useEffect, useMemo } from "react";
import {
  Grid,
  Stack,
  Typography,
  Popover,
  MenuList,
  Paper,
  ClickAwayListener,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
} from "@mui/material";
import "../../../Layout/sidebar.css";
import "../LandingPage/NewCss.css";
import Masonry from "react-masonry-css";
import InfiniteScroll from "react-infinite-scroll-component";
import { imagehttp, decodeToken, subPath } from "../../../Constants/Global";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Welcome from "../../Help/Welcome";
import eventEmitter from "../../../Constants/EventEmiter";
import HoverVideoPlayer from "react-hover-video-player";
import UseAxios from "../../../Constants/UseAxios";
import { GroupIcon, VaultIcon } from "../../../Utils/GroupVaultIcon";
import {
  ReadMoreArrow,
  SortByDropDown,
  SortByColor,
  SortByNormal,
  SortByCrossIcon,
  SavedAssetIcon,
  UnSavedAssetIcon,
  TimeIcon,
} from "../../../Utils/SortByDropDown";
import {
  CommentIcon,
  GramIcon,
  ViewIcon,
} from "../../../Utils/GramViewComment";
import { AssetViewSkeleton, AssetViewSkeleton1 } from "./AssetViewSkeleton";
import { useAppDispatch, useAppSelector } from "../../../Constants/hooks";
import {
  setItemName,
  setSearchValue,
  setSortBy,
} from "../../../Redux/Search/action";

interface Asset {
  id: any;
  savedStatus: any;
}

const AssetView: React.FC = () => {
  let api = UseAxios();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [assets, setAssets] = useState<any[]>([]);
  const [assetsCount, setAssetsCount] = useState<number[]>([0]);
  const { itemName, sortByValue } = useAppSelector(
    (state: any) => state.search
  );
  const companyId = decodeToken().companyId;
  const [page, setPage] = useState(0);
  const size = 13;
  const [isLoadingAsset, setIsLoadingAsset] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>();
  const [savedStatusDetails, setSavedStatusDetails] = useState<Asset[]>([]);
  const [savedStatusDetails1, setSavedStatusDetails1] = useState<Asset[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [popoverClass, setPopoverClass] = useState(false);

  const handleLoadMetadata = (meta: any) => {
    const { duration } = meta.target;
    const totalDuration = duration.toFixed(2);
    setDuration(totalDuration);
  };

  useEffect(() => {
    fetchAssetData(sortByValue, "Click");
  }, [itemName]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSortBy(event.target.value));
  };

  const handleOpenMenu = (event: any) => {
    document.body.classList.add("modal-opened");
    setAnchorEl(event.currentTarget);
    setPopoverClass(true);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenMenuside = (event: any) => {
    document.body.classList.add("modal-opened");
    setAnchorEl(event.currentTarget);
    setPopoverClass(false);
  };

  const getClassName = (className: string) => {
    if (className === "Audio") {
      return "bkgrdimge audio audioff";
    } else if (className === "Document") {
      return "bkgrdimge document documentff";
    } else {
      return "";
    }
  };

  // Asset View
  const fetchAssetData = async (types: string, loadType: string) => {
    if (isLoadingAsset) {
      return;
    }
    dispatch(setSortBy(types));
    setAnchorEl(null);

    let pageCount = page;
    if (loadType === "Click") {
      pageCount = 0;
      setIsLoadingAsset(true);
      window.scrollTo(0, 0);
    }

    const searchValRemoveHash = itemName.replace("#", "");

    try {
      const response = await api.get(
        `asset/filter-asset?companyId=${companyId}&size=${size}&page=${pageCount}&type=${
          types || "Recent"
        }&filter=${
          searchValRemoveHash != "" ? "true" : "false"
        }&title=${searchValRemoveHash}`
      );
      if (response.data.status === true) {
        setIsLoadingAsset(false);
        const newData = response.data.data;
        const data = response.data.data.assetResponse;
        // Set the updated savedStatus and isLoadingAsset
        const assetData = newData.assetResponse || [];
        if (assetData.length > 0) {
          // Reorder the asset content for each element in assetData
          const typeOrder = ["Video", "Image", "Audio"];
          // Reorder the asset content for each element in assetData
          const customSort = (a: any, b: any) => {
            const typeAIndex = typeOrder.indexOf(a.fieldType);
            const typeBIndex = typeOrder.indexOf(b.fieldType);
            // Move types not in the order to the end
            if (typeAIndex === -1) return 1;
            if (typeBIndex === -1) return -1;
            return typeAIndex - typeBIndex;
          };
          assetData.forEach((assetElement: any) => {
            if (
              assetElement.assetContent &&
              assetElement.assetContent.length > 0
            ) {
              assetElement.assetContent.sort(customSort);
            }
          });
        }

        const updatedSavedStatus = data.map((asset: any) => ({
          id: asset.id,
          savedStatus: asset.savedStatus,
        }));
        if (loadType === "Click") {
          setAssets(assetData);
          setSavedStatusDetails(updatedSavedStatus);
        } else {
          setAssets((prevGroup) => [...prevGroup, ...assetData]);
          setSavedStatusDetails((prevSavedStatus) => [
            ...prevSavedStatus,
            ...updatedSavedStatus,
          ]);
        }

        if (assetData.length < size) {
          setHasMore(false);
        }

        setPage(pageCount + 1);
        setAssetsCount(newData.saveCount ? newData.saveCount : 0);
      }
    } catch (error) {}
  };

  //Saved Asset
  const SavedAssetData = async (assetId: string) => {
    const requestBody = {
      assetId: assetId,
      type: "save",
    };
    try {
      const response = await api.post(`asset/save-asset`, requestBody);
      if (response) {
        const data = response.data;
        setAssetsCount(data.data.savedCount);
      }
    } catch (error) {}
  };
  // navigate
  const getAssetId = async (
    id: any,
    assetId: any,
    title: any,
    hashTag: any
  ) => {
    navigate(
      `/view?id=${encodeURIComponent(
        JSON.stringify(id)
      )}&a=${encodeURIComponent(
        JSON.stringify(assetId)
      )}&title=${encodeURIComponent(title)}&hashTag=${encodeURIComponent(
        hashTag
      )}`
    );
  };

  const handleClick = (id: number, saveCount: number, savedStatus: boolean) => {
    setSavedStatusDetails((prevSavedStatus) => {
      const updatedSavedStatus = prevSavedStatus.map((asset: any) => {
        if (asset.id === id) {
          return {
            ...asset,
            savedStatus: !asset.savedStatus,
          };
        }
        return asset;
      });

      return updatedSavedStatus;
    });
  };

  const merge = savedStatusDetails
    .concat(savedStatusDetails1)
    .filter(
      (value, index, self) => self.findIndex((v) => v.id === value.id) === index
    )
    .filter((value) => assets.some((asset) => asset.id === value.id));

  const headerSearch = (itemTitle: any) => {
    navigate("/landingAssetView");
    dispatch(setSearchValue(itemTitle));
    dispatch(setItemName(itemTitle));
  };

  return (
    <Box className="center-grid">
      {isLoadingAsset ? (
        <AssetViewSkeleton1 />
      ) : (
        <Grid container justifyContent="space-between">
          {sortByValue === "Saved" && !itemName ? (
            <Typography className="FF FontDgn">
              Explore Assets (Saved : {assetsCount})
            </Typography>
          ) : (
            <Typography className="FF FontDgn">Explore Assets</Typography>
          )}

          <Grid className="FF cur-pointer sortByDIv" textAlign="right">
            <Grid
              item
              onClick={handleOpenMenu}
              className=" d-flex align-items-center"
            >
              {sortByValue === "" || sortByValue === "Sort by" ? (
                <SortByNormal />
              ) : (
                <SortByColor />
              )}
              <Typography
                sx={{
                  color:
                    sortByValue === "" || sortByValue === "Sort by"
                      ? "#708090"
                      : "#119BFF",
                }}
                className="big-fn fs-11 ml_5"
              >
                {sortByValue === "" ? "Sort by" : sortByValue}
              </Typography>
            </Grid>

            {sortByValue === "" || sortByValue === "Sort by" ? (
              <SortByDropDown onClick={handleOpenMenuside} />
            ) : (
              <SortByCrossIcon
                fetchAssetData={() => fetchAssetData(sortByValue, "Click")}
                sortBy={() => dispatch(setSortBy("Recent"))}
              />
            )}

            <div>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                className={`backdrop paperbackdrop ${
                  popoverClass ? "right_pop" : ""
                }`}
                onClose={handleCloseMenu}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                slotProps={{
                  paper: {
                    style: {
                      borderRadius: "7px",
                      minWidth: "50px",
                    },
                  },
                }}
                anchorPosition={{ top: 20, left: 0 }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseMenu}>
                    <MenuList className="popoverAsset">
                      <RadioGroup
                        value={sortByValue}
                        onChange={handleOptionChange}
                        className="customRadio"
                      >
                        <MenuItem
                          className="h-30"
                          onClick={() => {
                            fetchAssetData("Trending", "Click");
                          }}
                        >
                          <FormControlLabel
                            value="Trending"
                            control={
                              <Radio
                                className="seleted-radio"
                                sx={{
                                  color: "#119BFF",
                                  "&.Mui-checked": {
                                    color: "#119BFF",
                                  },
                                }}
                              />
                            }
                            label="Trending"
                          />
                        </MenuItem>
                      </RadioGroup>
                      <RadioGroup
                        value={sortByValue}
                        onChange={handleOptionChange}
                      >
                        <MenuItem
                          className="h-30"
                          onClick={() => {
                            fetchAssetData("Most Gram", "Click");
                          }}
                        >
                          {" "}
                          <FormControlLabel
                            value="Most Gram"
                            control={
                              <Radio
                                className="seleted-radio"
                                sx={{
                                  color: "#119BFF",
                                  "&.Mui-checked": {
                                    color: "#119BFF",
                                  },
                                }}
                              />
                            }
                            label="Most Gram"
                          />
                        </MenuItem>
                      </RadioGroup>
                      <RadioGroup
                        value={sortByValue}
                        onChange={handleOptionChange}
                      >
                        <MenuItem
                          className="h-30"
                          onClick={() => {
                            fetchAssetData("Recent", "Click");
                          }}
                        >
                          {" "}
                          <FormControlLabel
                            value="Recent"
                            control={
                              <Radio
                                className="seleted-radio"
                                sx={{
                                  color: "#119BFF",
                                  "&.Mui-checked": {
                                    color: "#119BFF",
                                  },
                                }}
                              />
                            }
                            label="Recent"
                          />
                        </MenuItem>
                      </RadioGroup>
                      <RadioGroup
                        value={sortByValue}
                        onChange={handleOptionChange}
                      >
                        <MenuItem
                          className="h-30"
                          onClick={() => {
                            fetchAssetData("Saved", "Click");
                          }}
                        >
                          {" "}
                          <FormControlLabel
                            value="Saved"
                            control={
                              <Radio
                                className="seleted-radio"
                                sx={{
                                  color: "#119BFF",
                                  "&.Mui-checked": {
                                    color: "#119BFF",
                                  },
                                }}
                              />
                            }
                            label="Saved"
                          />
                        </MenuItem>
                      </RadioGroup>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Popover>
            </div>
          </Grid>
        </Grid>
      )}
      <div>
        <Welcome />
      </div>

      {isLoadingAsset ? (
        <AssetViewSkeleton />
      ) : (
        <div id="warpper_card_div">
          <InfiniteScroll
            dataLength={assets.length} //This is important field to render the next data
            next={() => fetchAssetData(sortByValue, "Normal")}
            hasMore={hasMore}
            scrollableTarget="warpper_card_div"
            className=""
            loader={
              <h6 className="d-flex justify-center">
                {" "}
                <CircularProgress className="primaryBlue" size={20} />
              </h6>
            }
            endMessage={
              <p className="fs-12 noMore">
                <b>No more data found...</b>
              </p>
            }
          >
            <Masonry
              breakpointCols={{ default: 3, 1000: 2, 800: 1 }}
              className="my-masonry-grid warpper_card  card-container"
              columnClassName="my-masonry-grid_column"
            >
              {assets.length > 0
                ? assets.map((result, index: number) => {
                    const fullName = `${result.firstName} ${result.lastName}`;
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                        key={result.id}
                        className="cardDesgin"
                      >
                        <Card className="cardDesgin">
                          <CardHeader
                            className="c-h-as landingpage padding_3_15_5_14"
                            avatar={
                              <Avatar
                                className="wh_30"
                                aria-label="recipe"
                                src={result.profilePicture}
                              ></Avatar>
                            }
                            action={
                              <IconButton disableRipple>
                                <TimeIcon />
                                <Typography className="asset-dsgn capitalize pr_10">
                                  {result.time}
                                </Typography>
                                {merge?.[index]?.id === result.id &&
                                merge?.[index]?.savedStatus === true ? (
                                  <>
                                    <SavedAssetIcon
                                      SavedAssetData={() =>
                                        SavedAssetData(result.id)
                                      }
                                      handleClick={async () =>
                                        handleClick(
                                          result.id,
                                          result.saveCount,
                                          result.savedStatus
                                        )
                                      }
                                    />
                                    <Typography
                                      color="#119BFF"
                                      className="asset-dsgn"
                                      onClick={() => {
                                        SavedAssetData(result.id);
                                        handleClick(
                                          result.id,
                                          result.saveCount,
                                          result.savedStatus
                                        );
                                      }}
                                      aria-label="Saved"
                                    >
                                      Saved
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <UnSavedAssetIcon
                                      SavedAssetData={() =>
                                        SavedAssetData(result.id)
                                      }
                                      handleClick={async () =>
                                        handleClick(
                                          result.id,
                                          result.saveCount,
                                          result.savedStatus
                                        )
                                      }
                                    />
                                    <Typography
                                      className="asset-dsgn"
                                      onClick={() => {
                                        SavedAssetData(result.id);
                                        handleClick(
                                          result.id,
                                          result.saveCount,
                                          result.savedStatus
                                        );
                                      }}
                                      aria-label="Save"
                                    >
                                      Save
                                    </Typography>
                                  </>
                                )}
                              </IconButton>
                            }
                            titleTypographyProps={{
                              fontSize: "14px",
                              fontWeight: "800",
                            }}
                            subheaderTypographyProps={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "#708090",
                            }}
                            title={fullName}
                            subheader={result.roles}
                          />

                          <Grid
                            item
                            className="cur-pointer "
                            onClick={(event) => {
                              if (
                                !(
                                  event.target instanceof HTMLElement &&
                                  event.target.id === "hashtag_asset"
                                )
                              ) {
                                getAssetId(
                                  result.id,
                                  result.assetId,
                                  result.title,
                                  result.hashTag
                                );
                              }
                            }}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                getAssetId(
                                  result.id,
                                  result.assetId,
                                  result.title,
                                  result.hashTag
                                );
                              }
                            }}
                          >
                            {result.assetContent[0] ? (
                              <CardActions
                                className={getClassName(
                                  result.assetContent[0].fieldType
                                )}
                              >
                                <>
                                  {result.assetContent[0].fieldType ===
                                    "Video" && (
                                    <HoverVideoPlayer
                                      className="bRadius-7 wh-194"
                                      muted
                                      videoSrc={
                                        result.assetContent[0].fieldType ===
                                        "Video"
                                          ? `${imagehttp}${result.assetContent[0].fieldValue}`
                                          : undefined
                                      }
                                      restartOnPaused
                                      playbackRangeEnd={3}
                                      disablePictureInPicture={true}
                                    />
                                  )}

                                  {result.assetContent[0].fieldType ===
                                    "Image" && (
                                    <CardMedia
                                      component="img"
                                      image={`${imagehttp}${result.assetContent[0].fieldValue}`}
                                      alt="Img"
                                      loading="lazy"
                                      sx={{
                                        objectFit: "scale-down;",
                                      }}
                                      height="194"
                                      className="bRadius-7"
                                    />
                                  )}

                                  {result.assetContent[0].fieldType ===
                                    "Audio" && (
                                    <>
                                      <CardMedia
                                        component="img"
                                        loading="lazy"
                                        image={`..${subPath}/assets/images/Imgead.svg`}
                                        alt="Audio"
                                        className="CardMedia-width-height bRadius-7"
                                      />
                                      <Typography
                                        title={result.assetContent[0].label}
                                        className="audioDoc hideDotSingleLine"
                                      >
                                        {result.assetContent[0].label}
                                      </Typography>
                                      <audio
                                        className="ml-8 display-none"
                                        src={`${imagehttp}${result.assetContent[0].fieldValue}`}
                                        onLoadedMetadata={handleLoadMetadata}
                                      >
                                        <track
                                          kind="captions"
                                          src="captions.vtt"
                                          label="English"
                                        />
                                      </audio>
                                      <Typography className="fs-12 fw-600 color_708090">
                                        {duration}
                                      </Typography>
                                    </>
                                  )}
                                  {result.assetContent[0].fieldType ===
                                    "Document" && (
                                    <>
                                      <CardMedia
                                        component="img"
                                        loading="lazy"
                                        image={`..${subPath}/assets/images/documentimage.svg`}
                                        alt="Doc"
                                        className="CardMedia-width-height bRadius-7"
                                      />
                                      <Typography
                                        title={result.assetContent[0].label}
                                        className="audioDoc hideDotSingleLine"
                                      >
                                        {result.assetContent[0].label}
                                      </Typography>
                                    </>
                                  )}
                                </>
                              </CardActions>
                            ) : null}

                            {result.groupsName.map((group: any) => (
                              <CardContent
                                className="groupVault marginTopMinus-5 padding-0079"
                                key={group.groupId} // Replace groupIndex with a unique identifier from the group object
                              >
                                <IconButton className="paddingLeft-7 ">
                                  <GroupIcon />
                                  <Typography className="hideDotSideBar mright-8 colorBlack fs-12  groupVault">
                                    {group.groupName}
                                  </Typography>
                                  <Typography className=" mright-8 color_119B">
                                    /
                                  </Typography>
                                  <VaultIcon />
                                  <Typography className="hideDotSideBar colorBlack groupVault fs-12">
                                    {group.vaultName}
                                  </Typography>
                                </IconButton>
                              </CardContent>
                            ))}
                            <CardContent className="padding-016">
                              <Typography
                                className="hideDotThreeLine fw-700 fs-15 titleDescription"
                                title={result.title}
                              >
                                {result.title}
                              </Typography>
                              <Typography
                                className="hideDotThreeLine color_7080 fw-500 fs-13 titleDescription"
                                title={result.description}
                              >
                                {result.description}
                              </Typography>
                              <Box className="hideDotTag maxWidth-310">
                                {!!result.hashTag && result.hashTag.length > 0 &&
                                  result.hashTag
                                    ?.toString()
                                    .split(",")
                                    .map((hashTag: any) => {
                                      return (
                                        <>
                                          <Typography
                                            key={hashTag}
                                            className=" inlineBlock narmalLineHeight color_119B fs-14 fw-500"
                                            id="hashtag_asset"
                                            onClick={() =>
                                              headerSearch(hashTag)
                                            }
                                          >
                                            {hashTag}
                                          </Typography>{" "}
                                        </>
                                      );
                                    })}
                              </Box>
                            </CardContent>
                            <CardActions className="justify-content-between">
                              <Stack
                                direction="row"
                                alignItems="center"
                                gap={0.5}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={0.5}
                                  className="ml-8"
                                >
                                  <GramIcon />
                                  <Typography
                                    variant="body1"
                                    className="fw-500 fs-13 color_7080 ml_4"
                                  >
                                    {result.totalGram}
                                  </Typography>
                                </Stack>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={0.5}
                                  className="ml-8"
                                >
                                  <ViewIcon />
                                  <Typography
                                    variant="body1"
                                    className=" fw-500 fs-13 color_7080 ml_4"
                                  >
                                    {result.viewCount}
                                  </Typography>
                                </Stack>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={0.5}
                                  className="ml-8"
                                >
                                  <CommentIcon />
                                  <Typography
                                    variant="body1"
                                    className=" fw-500 fs-14 color_7080 ml_4"
                                  >
                                    {result.commentCount}
                                  </Typography>
                                </Stack>
                              </Stack>
                              <Typography
                                variant="body1"
                                className="FontDgn fs-13 fw-700 ml-6 d-flex align-items-center capitalize"
                              >
                                {result.readTime} Read <ReadMoreArrow />
                              </Typography>
                            </CardActions>
                          </Grid>
                        </Card>
                      </Grid>
                    );
                  })
                : ""}
            </Masonry>
          </InfiniteScroll>
        </div>
      )}
    </Box>
  );
};
export default AssetView;
