import { Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { subPath } from "../../../../Constants/Global";
import MoreIcon from "@mui/icons-material/MoreVert";
import { deletePrivateChat, pinRecipientsOrGroupsForUser, unPinChat } from "../../../../Redux/Chatview/action";
import { useAppDispatch, useAppSelector } from "../../../../Constants/hooks";
import { getChatList } from "../../../../Redux/chatList/action";
import { Modal, Button } from '@mui/material';
import ConfirmationModal from '../ChatMessage/DeleteModel'; // import the modal component
const ChatMenu = ({ userData, chatId, senderId,handleMute,handleUnMute,chatDetails ,recipientId}: any) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
const [mutemessage, setMuteMessage]=useState(false);
const handleMuteclick = () => {
  setMuteMessage(!mutemessage);
  setAnchorElUser(null);

}

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleDeletePrivateChat = () => {
    
    const data = {
      senderId: senderId,
      chatId: chatId,
    };
    console.log("data---------->",data)
    dispatch(deletePrivateChat(data));
    setIsModalOpen(false);
   
    const datas = {
      userId: userData.id,
      searchValue: "",
      pageNo: 1,
      rowPerPage: 10,
    };
    const signal = new AbortController();
    setTimeout(()=>{
      dispatch(getChatList(datas, signal.signal));
    },2000);
    
  };

  const handleSetPinChat = () => {
    const data = {
      recipientId: senderId,
      userId: userData.id,
      groupId: ''
    };
    dispatch(pinRecipientsOrGroupsForUser(data));
  }
  const handleUnPinChat = () => {
    const data = {
      recipientId: senderId,
      userId: userData.id,
      groupId: ''
    };
    dispatch(unPinChat(data));
  }

  
  const chatListData = useAppSelector((state) => state?.chatListData?.chatList?.chatMembersList);
  console.log("chatListDatachatListData",chatListData);
  
  for (let index = 0; index < chatListData.length; index++) {
    const chatMember = chatListData[index].mutedChat;

    if (recipientId===chatListData[index].userId) {
      var mutted = chatListData[index].mutedChat
    }
  
  }
  const chatMember = chatListData?.find((member) => member?.userId);
console.log("chatMemberc", chatMember);
const userDatas = useAppSelector((state: any) => state.chatListData.userInfo);
console.log("userDatas",userDatas)
const handleMutemessage = () => {
  if (chatMember) {
    handleMute(chatMember.userId , true); 
  }
  setTimeout(()=>{
    const data = {
      userId: userDatas?.id,
      searchValue: "",
      pageNo: 1,
      rowPerPage: 10,
    };
    const signal = new AbortController();
    dispatch(getChatList(data, signal.signal));
  },1000)
}
const handleUnMutemessage = () => {
  if (chatMember) {
    handleUnMute(chatMember.userId, false); 
  }
  setTimeout(()=>{
    const data = {
      userId: userDatas?.id,
      searchValue: "",
      pageNo: 1,
      rowPerPage: 10,
    };
    const signal = new AbortController();
    dispatch(getChatList(data, signal.signal));
  },1000)
}
const handleOpenModal = () => {
  setIsModalOpen(true);
};

const handleCloseModal = () => {
  setIsModalOpen(false);
};

console.log(senderId,"senderId");


  return (
    <div>
      <IconButton
        className="iconbutton_chat"
        sx={{ paddingRight: "0px" }}
        size="large"
        edge="end"
        color="inherit"
        aria-label="user menu"
        onClick={handleOpenUserMenu}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
      <MenuItem onClick={handleMuteclick}>
  <Grid item sx={{ display: 'flex' }} onClick={mutted === false ? handleMutemessage : handleUnMutemessage}>
    <ListItemIcon>
      <img
        src={`..${subPath}/assets/images/images/${mutted === false ? "chat_notification" : "notification_chat"}.png`}
        alt="Mute Chat Icon"
        width="20"
        height="20"
      />
    </ListItemIcon>
    <ListItemText primary={mutted === false ? "Mute Chat" : "Unmute Chat"} className="moreitem_fs" />
  </Grid>
</MenuItem>
        <MenuItem onClick={handleUnPinChat}>
          <ListItemIcon>
            <img
              src={`..${subPath}/assets/images/images/pin-angle.png`}
              alt="Unpin Chat Icon"
              width="20"
              height="20"
            />
          </ListItemIcon>
          <ListItemText primary="Unpin Chat" className="moreitem_fs" />
        </MenuItem>
        {/* <MenuItem onClick={handleDeletePrivateChat}>
          <ListItemIcon>
            <img
              src={`..${subPath}/assets/images/images/trash.png`}
              alt="Delete Chat Icon"
              width="20"
              height="20"
            />
          </ListItemIcon>
          <ListItemText
            primary="Delete Chat"
            className="moreitem_fs"
            style={{ color: "#E7554A" }}
          />
        </MenuItem> */}
        <MenuItem onClick={handleOpenModal}>
        <ListItemIcon>
          <img
            src={`${subPath}/assets/images/images/trash.png`}
            alt="Delete Chat Icon"
            width="20"
            height="20"
          />
        </ListItemIcon>
        <ListItemText
          primary="Delete Chat"
          className="moreitem_fs"
          style={{ color: "#E7554A" }}
        />
      </MenuItem>
      <ConfirmationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={handleDeletePrivateChat}
       
      />
      </Menu>
    </div>
  );
};
export default ChatMenu;
